import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InspectionsApiServiceWrapper } from '@shared/services/sdk/inspections-api-service-wrapper.service';

import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { SharedModule } from '@shared/shared.module';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core';
import { DirectivesModule } from '../../directives/directives.module';
import { ReweighLogDetailHistTableCustomComponent } from './mat-table-cell-projections/reweigh-log-detail-hist-table-custom/reweigh-log-detail-hist-table-custom.component';
import { ReweighLogDetailTableCustomComponent } from './mat-table-cell-projections/reweigh-log-detail-table-custom/reweigh-log-detail-table-custom.component';
import { ReweighLogHistTableCustomComponent } from './mat-table-cell-projections/reweigh-log-hist-table-custom/reweigh-log-hist-table-custom.component';
import { ReweighLogScaleTestCustomComponentUniq } from './mat-table-cell-projections/reweigh-log-scale-test-custom-uniq/reweigh-log-scale-test-custom.component';
import { ReweighLogScaleTestCustomComponent } from './mat-table-cell-projections/reweigh-log-scale-test-custom/reweigh-log-scale-test-custom.component';
import { ShipmentDimensionsCustomComponent } from './mat-table-cell-projections/shipment-dimensions/shipment-dimensions-custom.component';
import { ReweighLogDetailHistComponent } from './reweigh-log-detail-hist/reweigh-log-detail-hist.component';
import { ReweighLogDetailsComponent } from './reweigh-log-details/reweigh-log-details.component';
import { ReweighLogHeaderHistComponent } from './reweigh-log-header-hist/reweigh-log-header-hist.component';
import { ReweighLogScaleTestsUniqComponent } from './reweigh-log-scale-tests-uniq/reweigh-log-scale-tests-uniq.component';
import { ReweighLogScaleTestsComponent } from './reweigh-log-scale-tests/reweigh-log-scale-tests.component';
import { ShipmentDimensionsComponent } from './shipment-dimensions/shipment-dimensions.component';

const components = [
  ReweighLogDetailsComponent,
  ReweighLogDetailHistComponent,
  ReweighLogHeaderHistComponent,
  // custom components for dynamic projection
  ReweighLogHistTableCustomComponent,
  ReweighLogDetailHistTableCustomComponent,
  ReweighLogDetailTableCustomComponent,
  ReweighLogScaleTestCustomComponent,
  ReweighLogScaleTestsComponent,
  ShipmentDimensionsComponent,
  ShipmentDimensionsCustomComponent,
  ReweighLogScaleTestsUniqComponent,
  ReweighLogScaleTestCustomComponentUniq,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [DirectivesModule, CommonModule, SharedModule, XpoIconModule],
  providers: [],
})
export class ReweighLogCommonModule {}
