import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InspectionsApiServiceWrapper } from '@shared/services/sdk/inspections-api-service-wrapper.service';
import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';

import { SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import {
  XpoLtlReleaseNotesComponent,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherComponent,
  XpoLtlRoleSwitcherModule,
} from '@xpo-ltl/ngx-ltl';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoFooterModule,
  XpoIconModule,
  XpoPageNotFoundRoutingModule,
  XpoShellModule,
  XpoSnackBarModule,
} from '@xpo-ltl/ngx-ltl-core';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { DocumentManagementApiService } from '@xpo-ltl/sdk-documentmanagement';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { InspectionsApiService } from '@xpo-ltl/sdk-inspections';
import { ReweighApiService, SdkReweighModule } from '@xpo-ltl/sdk-reweigh';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DialogModule } from '@dialogs/dialog.module';
import { environment } from '@environments/environment';
import { ReweighDefaultErrorHandlerService } from '@shared/services/reweigh-default-error-handler.service';
import { ReweighDefaultHttpInterceptor } from '@shared/services/reweigh-default-http-interceptor';
import { SharedModule } from '@shared/shared.module';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RootModule } from './components/root/root.module';
import { CoreModule } from './core/core.module';
import { CypressUtilities } from './cypress/cypress-utilities';
import { ReweighLogHeaderViewsModule } from './reweigh-log-headers/reweigh-log-header-views/reweigh-log-header-views.module';
import { ReweighLogSummaryPageModule } from './reweigh-log-headers/reweigh-log-summary/reweigh-log-summary.module';

// TODO: change environment.ts and config.json when SSO is setted up for Reweigh
export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

const sdkModules: any[] = [
  SdkAppMetadataModule,
  SdkInfrastructureModule,
  SdkLocationModule,
  SdkReweighModule,
  SdkUserPreferenceModule,
];

const ltlModules = [XpoLtlReleaseNotesModule, XpoLtlRoleSwitcherModule];

const ltlCoreModules = [
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoShellModule,
  XpoSnackBarModule,
  XpoIconModule,
  XpoFooterModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    DialogModule,
    CoreModule,
    SharedModule,
    RootModule,
    // make sure SdkLoggingModule is imported after XpoMaintenanceModule for better performance.
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.REWEIGH }),
    BrowserAnimationsModule,
    ...ltlModules,
    ...ltlCoreModules,
    ...sdkModules,
    // For SSO
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoLtlAuthUiModule,
    AppRoutingModule, // KEEP LAST!!!
    XpoPageNotFoundRoutingModule,
    ReweighLogSummaryPageModule,
    ReweighLogHeaderViewsModule,
    // KEEP LAST!!!
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ReweighDefaultHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ReweighDefaultErrorHandlerService,
    },

    DataApiService,
    NotificationService,
    DocumentManagementApiService,
    ReweighApiServiceWrapper,
    InspectionsApiServiceWrapper,
    InspectionsApiService,
    ...CypressUtilities.getProviders(),
  ],
  entryComponents: [XpoLtlReleaseNotesComponent, XpoLtlRoleSwitcherComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
