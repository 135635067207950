import { Injectable } from '@angular/core';
import { ExtendedInspectionDimension } from '@shared/classes/entities/extended-inspection-dimension';
import { ReweighError } from '@shared/classes/error/reweigh-error';
import { ExtendedInspectionDimensionSrcCollection } from '@shared/enums/extended-inspection-dimension-src-collection';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ApiServiceWrapper } from '@shared/services/sdk/api-service-wrapper';
import { UserRoleService } from '@shared/services/user-role';
import { InspectionDimensionsDetailCd } from '@xpo-ltl/sdk-common';
import {
  GetInspectionDimensionsForShipmentPath,
  GetInspectionDimensionsForShipmentQuery,
  GetInspectionDimensionsForShipmentResp,
  InspectionDimension,
  InspectionsApiService,
} from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InspectionsApiServiceWrapper extends ApiServiceWrapper<InspectionsApiService> {
  static readonly DEFAULT_GET_INSP_DIMS_FOR_SHIPMENT_DETAIL_CDS: InspectionDimensionsDetailCd[] = [
    InspectionDimensionsDetailCd.HANDLING_UNIT_DIMENSIONS,
    InspectionDimensionsDetailCd.INSPECTION_DIMENSIONS,
    InspectionDimensionsDetailCd.INSPECTION_DIMENSIONS_HIST,
    InspectionDimensionsDetailCd.OPERATIONS_DIMENSIONS,
    InspectionDimensionsDetailCd.EMPLOYEE_FULL_NAME,
  ];

  constructor(
    protected baseService: InspectionsApiService,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected userRoleService: UserRoleService
  ) {
    super(baseService, reweighAppNotificationService, userRoleService);
  }

  /**
   * https://xpo.atlassian.net/browse/LEI-1887
   *
   * @param aShipmentInstId
   */
  getInspectionDimensionsForShipment(
    aShipmentInstId: number,
    aDetailCds?: InspectionDimensionsDetailCd[]
  ): Observable<List<ExtendedInspectionDimension>> {
    if (isNaN(+aShipmentInstId) || aShipmentInstId <= 0) {
      const msg: string = 'given parameter aShipmentInstId is not a number or <=0';
      console.error(msg + ':', aShipmentInstId);
      throwError(new ReweighError(msg));
    } else {
      let lDetailCds: InspectionDimensionsDetailCd[];
      if (aDetailCds) {
        lDetailCds = aDetailCds;
      } else {
        lDetailCds = InspectionsApiServiceWrapper.DEFAULT_GET_INSP_DIMS_FOR_SHIPMENT_DETAIL_CDS;
      }

      const pathParams: GetInspectionDimensionsForShipmentPath = new GetInspectionDimensionsForShipmentPath();
      const pathQry: GetInspectionDimensionsForShipmentQuery = new GetInspectionDimensionsForShipmentQuery();
      pathParams.shipmentInstanceId = aShipmentInstId;
      pathQry.inspectionDimensionsDetailCds = lDetailCds;
      // tslint:disable-next-line:max-line-length
      const inspectionDimensionsForShipment: Observable<GetInspectionDimensionsForShipmentResp> = this.baseService.getInspectionDimensionsForShipment(
        pathParams,
        pathQry,
        this.getSimpleDataOptions()
      );
      return inspectionDimensionsForShipment.pipe(
        map((response: GetInspectionDimensionsForShipmentResp) => {
          let result: ExtendedInspectionDimension[] = [];
          // console.debug('getInspectionDimensions response:', response);
          result = result.concat(
            this.getAsExtendedInspectionDimensions(
              response.inspectionDimensions,
              ExtendedInspectionDimensionSrcCollection.INSPECTION_DIMENSIONS
            )
          );
          result = result.concat(
            this.getAsExtendedInspectionDimensions(
              response.operationDimensions,
              ExtendedInspectionDimensionSrcCollection.OPERATION_DIMENSIONS
            )
          );
          result = result.concat(
            this.getAsExtendedInspectionDimensions(
              response.handlingUnitDimensions,
              ExtendedInspectionDimensionSrcCollection.HANDLING_UNIT_DIMENSIONS
            )
          );
          result = result.concat(
            this.getAsExtendedInspectionDimensions(
              response.inspectionDimensionHistory,
              ExtendedInspectionDimensionSrcCollection.INSPECTION_DIMENSIONS_HISTORY
            )
          );
          return List(result);
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      );
    }
  }

  protected getAsExtendedInspectionDimensions(
    inspectionDimensions: InspectionDimension[],
    aExtendedInspectionDimensionSrcCollection: ExtendedInspectionDimensionSrcCollection
  ): ExtendedInspectionDimension[] {
    const result: ExtendedInspectionDimension[] = [];
    if (inspectionDimensions?.length > 0) {
      for (let i = 0; i < inspectionDimensions.length; i++) {
        const inspectionDimension: InspectionDimension = inspectionDimensions[i];
        const extDim: ExtendedInspectionDimension = ExtendedInspectionDimension.toExtendedInspectionDimension(
          inspectionDimension,
          aExtendedInspectionDimensionSrcCollection
        );
        result.push(extDim);
      }
    }
    return result;
  }
}
