<div class="overall-component">
<!--  <a (click)="toggleCollapseExpandData($event)"-->
<!--    >Toggle for not working cols col:{{ visibleColumns?.length || 0 }} dataLength:{{ dataSource?.data?.length || 0 }}</a-->
<!--  >-->
  <!--  Title -->
  <h2
    class="section-title"
    matTooltip="Click to load data"
    (click)="onLoadClicked($event)"
    *ngIf="isTitleSectionDisplayed"
  >
    <!--    Load data-->
    <ng-template *ngIf="isLoadDataButtonDisplayed()">
      <button
        mat-icon-button
        *ngIf="!dataIsLoaded"
        (click)="onLoadClicked($event)"
        matTooltip="Click to load Detail's history"
        class="expand-btn"
      >
        <mat-icon>add</mat-icon>
      </button>
    </ng-template>
    <!--    Title-->
    {{ getMainTitle() }}
    <!--    more/less data-->
    <!--    <mat-slide-toggle-->
    <!--      (click)="onCollapseExpandColumnsClicked($event, getComponentForColumnsExpandCollapse())"-->
    <!--    >{{COLLAPSE_EXPAND_LABEL_CONTENT}}</mat-slide-toggle>-->
  </h2>
  <!--Table-->
  <div class="table-container">
    <div
      class="actions"
      *ngIf="false && dataSource.data?.length > 0"
      (click)="toggleCollapseExpandData($event)"
      [matTooltip]="getMatTableMoreLessTooltip()"
    >
      <button mat-icon-button class="expand-btn">
        <mat-icon *ngIf="!isTableColumnsExpanded">chevron_right</mat-icon>
        <mat-icon *ngIf="isTableColumnsExpanded">chevron_left</mat-icon>
      </button>
    </div>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      *ngIf="dataSource.data?.length > 0"
      [matSortActive]="getSortDefaultColumnName()"
      [matSortDirection]="getSortDefaultDirection()"
      class="ui-entity-data-table"
    >
      <ng-container
        *ngFor="let uiEntityProperty of uiEntityProperties; let colIndex = index"
        [matColumnDef]="uiEntityProperty.propertyName"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [matTooltip]="getElementTableHeaderTooltip(uiEntityProperty)"
          [ngClass]="getNgClassForHeaderAndCell(uiEntityProperty)"
        >
          {{ getElementTableHeaderTitle(uiEntityProperty) }}
        </th>
        <td mat-cell [ngClass]="getNgClassForHeaderAndCell(uiEntityProperty)" *matCellDef="let rowData">
          <ng-container [ngSwitch]="uiEntityProperty.variableType">
            <ng-container *ngSwitchCase="UiEntityPropertyTypeEnum.CUSTOM">
              <ng-template [rowData]="rowData" [uiEntityProperty]="uiEntityProperty" uiEntityTableCell></ng-template>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ getValueFromRowData(rowData, uiEntityProperty) }}
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: visibleColumns"
        (click)="selection.toggle(row)"
        [ngClass]="{ hovered: row.hovered, highlightedTableRow: selection.isSelected(row) }"
        (mouseover)="row.hovered = true"
        (mouseout)="row.hovered = false"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="visibleColumns?.length">
          No data matching the filter.
        </td>
      </tr>
    </table>
  </div>
</div>
