import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatNumberOrNaPipe' })
export class FormatNumberOrNaPipe implements PipeTransform {
  static readonly DEFAULT_NULL_VALUE: string = 'N/A';

  static getFormattedNumber(src: any): string {
    let result: string = '';
    if (src === null || src === undefined) {
      result = FormatNumberOrNaPipe.DEFAULT_NULL_VALUE;
    } else {
      if (typeof src === 'number') {
        result = formatNumber(src, 'en-US');
      } else {
        result = this.getFormattedNumber(+src);
      }
    }

    // console.debug('getFormattedNumber src:' + src + ' result:' + result);

    return result;
  }

  transform(src: any): string {
    return FormatNumberOrNaPipe.getFormattedNumber(src);
  }
}
