<div class="reweigh-header-record-details-cell">
  <xpo-busy-loader
    class="reweigh-header-record-details-cell_loader"
    *ngIf="!logDetails$; else logHeaderDataTpl"
  ></xpo-busy-loader>
  <ng-template #logHeaderDataTpl>
    <div class="reweigh-header-record-details-cell_no-records" *ngIf="(logDetails$ | async)?.isEmpty(); else loaderTpl">
      <mat-icon>insert_drive_file</mat-icon>
      No records to show
    </div>
    <ng-template #loaderTpl>
      <reweigh-log-details
        [tableDataAsList]="logDetails$ | async"
        [proNbrTxtInput]="proNumberTxt"
        [viewType]="ReweighLogDetailsViewType.LOG_HEADERS_GRID"
      ></reweigh-log-details>
    </ng-template>
  </ng-template>
</div>
