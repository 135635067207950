import { Injectable } from '@angular/core';
import { ReweighError } from '@shared/classes/error/reweigh-error';
import { CollapsableExpandableComponent } from '@shared/enums/collapsable-expandable-component';
import { CollapsableExpandable } from '@shared/interfaces/collapsable-expandable';

@Injectable({
  providedIn: 'root',
})
export class ReweighLogSummaryService {
  protected enumExpandableComponentMap: Map<CollapsableExpandableComponent, CollapsableExpandable> = new Map([
    [CollapsableExpandableComponent.ReweighLogDetailsComponentFullSum, undefined],
    [CollapsableExpandableComponent.ReweighLogDetailsComponentFullSum, undefined],
    [CollapsableExpandableComponent.ReweighLogDetailsComponentDisputeSum, undefined],
    [CollapsableExpandableComponent.ShipmentDimensionsFullSum, undefined],
    [CollapsableExpandableComponent.ShipmentDimensionsDisputeSum, undefined],
    [CollapsableExpandableComponent.HeaderHistoryFullSum, undefined],
    [CollapsableExpandableComponent.HeaderHistoryDisputeSum, undefined],
    [CollapsableExpandableComponent.DetailsHistFullSum, undefined],
    [CollapsableExpandableComponent.DetailsHistDisputeSum, undefined],
    [CollapsableExpandableComponent.ScaleTestsUniqFullSum, undefined],
    [CollapsableExpandableComponent.ScaleTestsUniqDisputeSum, undefined],
    [CollapsableExpandableComponent.ScaleTestsFullSum, undefined],
    [CollapsableExpandableComponent.ScaleTestsDisputeSum, undefined],
  ]);

  constructor() {}

  setCollapsableExpandableComponent(
    componentEnum: CollapsableExpandableComponent,
    expandableCollapsable: CollapsableExpandable
  ) {
    this.enumExpandableComponentMap.set(componentEnum, expandableCollapsable);
  }

  onCollapseExpandClicked(aComponent: CollapsableExpandableComponent) {
    const lExpandableComponent: CollapsableExpandable = this.enumExpandableComponentMap.get(aComponent);
    if (lExpandableComponent) {
      // can be undefined if component not loaded
      // console.debug('toggle expand/collapse, columns:', lExpandableComponent);
      lExpandableComponent.toggleCollapseExpand();
    } else {
      const lMsg: string = 'Component not found, cant collapse/expand it';
      console.error(lMsg, aComponent);
      throw new ReweighError(lMsg);
    }
  }
}
