export class MiscUtil {
  /**
   * <xpo-icon [iconName]="getTrueFalseIconName(aBoolVal)"></xpo-icon>
   *
   * @param uiEntityData
   */
  static getTrueFalseIconName(uiEntityData: boolean): string {
    let result: string = 'close';
    if (uiEntityData) {
      result = 'checkmark';
    }
    return result;
  }
}
