export enum CollapsableExpandableComponent {
  ReweighLogDetailsComponentFullSum,
  ReweighLogDetailsComponentDisputeSum,
  ShipmentDimensionsFullSum,
  ShipmentDimensionsDisputeSum,
  HeaderHistoryFullSum,
  HeaderHistoryDisputeSum,
  DetailsHistFullSum,
  DetailsHistDisputeSum,
  ScaleTestsUniqFullSum,
  ScaleTestsUniqDisputeSum,
  ScaleTestsFullSum,
  ScaleTestsDisputeSum,
}
