import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { OperatorLogDetail } from '@shared/classes/entities/operator-log-detail';
import { UiEntities } from '@shared/classes/entities/ui-entities';
import { UiEntity } from '@shared/classes/entities/ui-entity';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { MiscUtil } from '@shared/classes/misc-util';
import { Reweigh } from '@shared/classes/reweigh';
import { UiEntityExpandableMatTableComponent } from '@shared/components/ui-entity-expandable-mat-table/ui-entity-expandable-mat-table.component';
import { CollapsableExpandableComponent } from '@shared/enums/collapsable-expandable-component';
import { ReweighLogDetailsViewType } from '@shared/enums/reweigh-log-detail-view-type';
import { ConstantsService } from '@shared/services/constants';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import { ReweighService } from '@shared/services/reweigh-service/reweigh.service';
import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { UserRoleService } from '@shared/services/user-role';
import { List } from 'immutable';
import { Subscription } from 'rxjs';
import { UiEntityTableCellDirective } from '../../../directives/ ui-entity-table-cell-directive';
import { ReweighLogDetailTableCustomComponent } from '../mat-table-cell-projections/reweigh-log-detail-table-custom/reweigh-log-detail-table-custom.component';

@Component({
  selector: 'reweigh-log-details',
  templateUrl:
    '../../../shared/components/ui-entity-expandable-mat-table/ui-entity-expandable-mat-table.component.html',
  styleUrls: [
    '../lib/css/reweigh-log-table-section.scss',
    '../../../shared/components/ui-entity-expandable-mat-table/ui-entity-expandable-mat-table.component.scss',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('425ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // for @viewChild and inputs to work with inheritence
  providers: [{ provide: ReweighLogDetailsComponent, useExisting: UiEntityExpandableMatTableComponent }],
})
export class ReweighLogDetailsComponent
  extends UiEntityExpandableMatTableComponent<OperatorLogDetail, ReweighLogDetailTableCustomComponent>
  implements OnChanges {
  @Input() collapserExpander: CollapsableExpandableComponent;

  /**
   * Cant be pushed on the parent
   * @param content
   */
  @ViewChild(MatSort, { static: false }) set content(content: ElementRef) {
    this.tableSort = content;
    if (this.tableSort) {
      this.dataSource.sort = this.tableSort;
    }
  }

  @ViewChildren(UiEntityTableCellDirective) uiEntityTableCellDirectiveViewChildren: QueryList<
    UiEntityTableCellDirective<OperatorLogDetail>
  >;

  @Input()
  tableDataAsList: List<OperatorLogDetail>;

  @Input() viewType: ReweighLogDetailsViewType = ReweighLogDetailsViewType.DEFAULT;

  @Input() proNbrTxtInput: string;

  @Input() isHeaderTitleDisplayedInput: boolean = true;

  protected reweighServiceSubscription: Subscription;

  constructor(
    protected constantsService: ConstantsService,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    protected changeDetectorRefs: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected userRoleService: UserRoleService,
    protected reweighService: ReweighService,
    protected reweighLogSummaryService: ReweighLogSummaryService,
    protected changeDetection: ChangeDetectorRef
  ) {
    super(reweighLogSummaryService, changeDetection);

    this.reweighServiceSubscription = this.reweighService.subject.subscribe((reweigh: Reweigh) => {
      // console.debug('reloading datasource data with:', this.dataSource.data);

      // set lifScaleTests
      if (
        this.viewType !== ReweighLogDetailsViewType.LOG_HEADERS_GRID &&
        this.userRoleService.currentUserHasWriteAccess()
      ) {
        if (this.tableDataAsList && !this.tableDataAsList.isEmpty()) {
          this.tableDataAsList.forEach((operatorLogDetail: OperatorLogDetail) => {
            operatorLogDetail.setFromLiftScaleCalibrationHeaders(reweigh.liftScaleCalibrationHeaders);
          });
          // to be executed after component loading
          this.dataSource.data = this.tableDataAsList?.toArray();
          this.dataIsLoaded = true;
        } // end if
      } // end if
      this.changeDetection.markForCheck();
    }); // end subscription
  }

  getComponentFactoryToProject(): ComponentFactory<ReweighLogDetailTableCustomComponent> {
    // tslint:disable-next-line:max-line-length
    const lComponentFactoryToProject: ComponentFactory<ReweighLogDetailTableCustomComponent> = this.componentFactoryResolver.resolveComponentFactory<
      ReweighLogDetailTableCustomComponent
    >(ReweighLogDetailTableCustomComponent);
    return lComponentFactoryToProject;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.debug('onChange logDetailsComp, dataSourceData:', changes, this.dataSource.data);
    if (changes['tableDataAsList']) {
      // input has changed, resetting with new list the datasource data
      this.dataSource.data = this.tableDataAsList.toArray();
      this.changeDetection.markForCheck();
    }
  }

  /* tslint:disable use-lifecycle-interface */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // set what's needed as data
    setTimeout(() => {
      // disable expanded
      this.isExpandableRowsEnabled =
        this.viewType !== ReweighLogDetailsViewType.LOG_HEADERS_GRID &&
        this.userRoleService.currentUserHasWriteAccess();
      this.resetTableColumns();

      this.isTitleSectionDisplayed = this.isHeaderTitleDisplayedInput;
      this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
      this.changeDetection.markForCheck();
    });
    // each time one element with that directive has been inserted into the DOM, then do...
    this.uiEntityTableCellDirectiveViewChildren.changes.subscribe((aUiEntityTableCellDirectiveQryList: any) => {
      // note that we receive aUiEntityTableCellDirectiveQryList as argument,
      // but its the same as this.uiEntityTableCellDirectiveViewChildren so we can use that one as well
      setTimeout(() => {
        this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
        this.changeDetection.markForCheck();
      });
    });
  }

  getUiEntity(): UiEntity {
    let result: UiEntity;
    if (this.viewType === ReweighLogDetailsViewType.DISPUTES) {
      result = UiEntities.LOG_DETAIL_FOR_DISPUTES_SUMMARY;
    } else {
      result = UiEntities.LOG_DETAIL;
    }
    return result;
  }

  getMainTitle(): string {
    return 'Log Details (' + this.tableDataAsList.size + ')';
  }

  isLoadDataButtonDisplayed(): boolean {
    return false;
  }

  // override
  getSortDefaultColumnName(): string {
    return 'reweighDateTimeLcl';
  }

  // override
  getSortDefaultDirection(): SortDirection {
    return this.SORT_DIRECTION_ASC;
  }

  /**
   * Override
   */
  getVisibleColumns(): List<string> {
    const tmp: string[] = super.getVisibleColumns().toArray();
    if (!this.userRoleService.currentUserHasWriteAccess()) {
      const lRemovedFields: string[] = [
        'operatorId',
        'operatorFullName',
        'auditInfo.createdById',
        'auditInfo.createByPgmId',
        'auditInfo.updateById',
        'auditInfo.updateByPgmId',
        'correlationId',
      ];
      for (let i = 0; i < tmp.length; i++) {
        const colName: string = tmp[i];
        if (lRemovedFields.includes(colName)) {
          tmp.splice(i, 1);
          i--;
        }
      }
    }
    return List(tmp);
  }

  getExpandedUiEntity(): UiEntity {
    return UiEntities.LIFT_SCALE_CALIBRATION_HEADER_FOR_LOG_DETAILS;
  }

  isExpandDetailRowsButtonVisible(): boolean {
    return this.isExpandableRowsEnabled;
  }

  getCollapserExpander(): CollapsableExpandableComponent | undefined {
    return this.collapserExpander;
  }

  getTestPassedIconName(uiEntityData: boolean): string {
    return MiscUtil.getTrueFalseIconName(uiEntityData);
  }
}
