import { ReweighStatusCd } from '@xpo-ltl/sdk-common';

import { ReweighError } from '@shared/classes/error/reweigh-error';
import { KeyValueLabel } from '@shared/interfaces';

export class ReweighLogStatusHelper {
  /** Return the keys of ReweighStatusCd */
  static getKeys(): string[] {
    const keys = Object.keys(ReweighStatusCd);
    return keys;
  }

  /** Return the key base on the enum */
  static getKey(status: ReweighStatusCd): string {
    const keys = this.getKeys();
    const key = keys.find((k) => status === ReweighStatusCd[k]);
    return key;
  }

  /** Return the array of { key, value } from ReweighStatusCd */
  static getKeyValuesArray(): KeyValueLabel[] {
    const keys = this.getKeys();
    const keyValues = keys.map((k) => {
      return {
        key: k,
        value: ReweighStatusCd[k],
        label: ReweighLogStatusHelper.getSpaceFormattedCamelCase(ReweighStatusCd[k]),
        tooltip: ReweighLogStatusHelper.getSpaceFormattedCamelCase(ReweighStatusCd[k]),
      };
    });
    return keyValues;
  }

  static getSpaceFormattedCamelCase(source: string): string {
    const reg: RegExp = new RegExp(/([a-z0-9])([A-Z])/g);

    if (source && source.trim().length > 0) {
      return source.replace(reg, '$1 $2');
    } else {
      throw new ReweighError('Reweigh Status cannot be empty.');
    }
  }
}
