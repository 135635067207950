import {formatNumber} from '@angular/common';
import {DateHelper} from '@shared/classes/date-helper';
import {UiEntities} from '@shared/classes/entities/ui-entities';
import {ReweighMoreInfoCdHelper} from '@shared/classes/more-info-cd-helper/more-info-cd-helper';
import {Reweigh} from '@shared/classes/reweigh';
import {MiscUtil} from '@shared/classes/utils/misc-util';
import {UiEntityUtil} from '@shared/classes/utils/ui-entity-util';
import {ReweighLogDateFormats} from '@shared/enums/reweigh-log/date-formats.enum';
import {YesNoOptionPipe} from '@shared/pipes';
import {UserRoleService} from '@shared/services/user-role';
import {XpoAgGridBoardColumn} from '@xpo-ltl/ngx-ltl-board-grid';
import {XpoAgGridColumns} from '@xpo-ltl/ngx-ltl-grid';
import {LogHeader} from '@xpo-ltl/sdk-reweigh';
import {ColDef, ITooltipParams, ValueFormatterParams, ValueGetterParams} from 'ag-grid-community';
import {get as _get} from 'lodash';
import {
  CertificateCellRendererComponent
} from '../../../reweigh-log-headers/reweigh-log-headers-home/cell-renderers/certificate-cell-renderer/certificate-cell-renderer.component';
import {
  DisplayIndicatorRendererComponent
} from '../../../reweigh-log-headers/reweigh-log-headers-home/cell-renderers/display-indicator-renderer/display-indicator-renderer.component';
import {
  ExceptionDescriptionRendererComponent
} from '../../../reweigh-log-headers/reweigh-log-headers-home/cell-renderers/exception-description-cell-renderer/exception-description-cell-renderer.component';
import {
  ProNumberRendererComponent
} from '../../../reweigh-log-headers/reweigh-log-headers-home/cell-renderers/pro-number-cell-renderer/pro-number-cell-renderer.component';
import {
  RwExceptionCdRendererComponent
} from '../../../reweigh-log-headers/reweigh-log-headers-home/cell-renderers/rw-exception-cd-renderer/rw-exception-cd-renderer.component';
import {
  RwYesNoRendererComponent
} from '../../../reweigh-log-headers/reweigh-log-headers-home/cell-renderers/rw-yes-no-renderer-component/rw-yes-no-renderer.component';

export class ReweighLogHeaderGridServiceColdefs {
  constructor(protected userRoleService: UserRoleService) {
  }

  static readonly YES_NO_OPTION_COLUMN_WIDTH: number = 100;

  static secondaryColumns: XpoAgGridBoardColumn[] = [
    {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName('correctionId'),
      field: 'correctionId',
      hide: true,
      initialHide: true,
    },
    ReweighLogHeaderGridServiceColdefs._getYesNoOption('imageCreatedInd'),
    ReweighLogHeaderGridServiceColdefs.getColumnUsdAmountDefinition('revenueOriginAmount'),
    ReweighLogHeaderGridServiceColdefs.getColumnUsdAmountDefinition('revenueChangeAmount'),
    ReweighLogHeaderGridServiceColdefs.getColumnUsdAmountDefinition('revenuePreviewAmount'),
    ReweighLogHeaderGridServiceColdefs.getColumnUsdAmountDefinition('revenueCorrAmount'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('corrCommSequenceNbr'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('corrCommClass'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('corrCommNmfcItem'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('corrCommPieceCount'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('corrCommOriginWeight'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('corrCommNewWeight'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('standardTolWeight'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('customerTolWeight'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('customerTolCustomerMadcode'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('customerTolCustomerId'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('customerTolPartyTypeCd'),
    // TODO: Missing field in SDK customerTolParentInd
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('palletTolWeight'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('palletTolCustomerMadcode'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('palletTolCustomerId'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('palletTolAgreementId'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('palletTolRuleset'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('retryCount'),
    ReweighLogHeaderGridServiceColdefs.getDefaultColInitialHide('shipmentInstanceId'),
    ReweighLogHeaderGridServiceColdefs.getDefaultColInitialHide('auditInfo.createdById'),
    ReweighLogHeaderGridServiceColdefs.getDefaultColInitialHide('auditInfo.createByPgmId'),
    ReweighLogHeaderGridServiceColdefs.getDefaultColInitialHide('auditInfo.updateByPgmId'),
    ReweighLogHeaderGridServiceColdefs.getDefaultCol('correlationId'),
  ];

  static getPrimaryColumns(userRoleService: UserRoleService): XpoAgGridBoardColumn[] {
    const result: XpoAgGridBoardColumn[] = [
      {
        ...XpoAgGridColumns.SelectionWithSelectAll,
        pinned: 'left',
      },
      {
        headerName: '',
        width: 40,
        filter: false,
        valueGetter: (params) => '',
        cellClass: 'log-header-id-cell',
        lockVisible: true,
        cellRenderer: 'agGroupCellRenderer',
        pinned: 'left',
        sortable: false,
        headerTooltip: 'Expand/collapse more information',
        tooltipValueGetter: (params) => 'Click to expand/collapse more information for ' + params?.data?.logHeaderId,
      },
      {
        headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName('logHeaderId'),
        field: 'logHeaderId',
        cellClass: 'log-header-id-cell',
        lockVisible: true,
        cellRenderer: 'logHeaderRendererComponent',
        pinned: 'left',
        width: 140,
      },
      {
        headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName('proNbr'),
        field: 'proNbr',
        cellRendererFramework: ProNumberRendererComponent,
        width: 160,
        pinned: 'left',
      },
      ReweighLogHeaderGridServiceColdefs._getDisplayIndOptions('displayInd', 'Is given row to be displayed or not'),
      {
        headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName('imageCreatedInd'),
        cellRendererFramework: CertificateCellRendererComponent,
        valueGetter: (params) => YesNoOptionPipe.transformStatic(params.data.imageCreatedInd),
        field: 'imageCreatedInd',
      },
      {
        headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName('statusCd'),
        field: 'statusCd',
        width: 128,
        cellClass: (params) => ReweighLogHeaderGridServiceColdefs._getStatusCdClass(params.data.statusCd),
      },
      ReweighLogHeaderGridServiceColdefs._getExceptionCdDefinition(),
      {
        headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName('exceptionDescription'),
        field: 'exceptionDescription',
        width: 330,
        cellClass: 'exception-description',
        // wrapText: true,// wrapping not working
        // autoHeight: true,
        cellRendererFramework: ExceptionDescriptionRendererComponent,
        filter: 'xpoStringFilterComponent',
      },
      ReweighLogHeaderGridServiceColdefs.getColumnUsdAmountDefinition('revenueChangeAmount'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('shipmentWeight'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('reweighWeight'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('weightChange'),
      ReweighLogHeaderGridServiceColdefs._getYesNoOption('exceedsToleranceInd'),
      ReweighLogHeaderGridServiceColdefs.getDefaultCol('appliedTolTypeCd'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('appliedTolWeight'),
      ReweighLogHeaderGridServiceColdefs.getOperatorFullName(),
      ReweighLogHeaderGridServiceColdefs.getDefaultCol('operatorId'),
      ReweighLogHeaderGridServiceColdefs._getYesNoOption('pltProInd'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('childProCount'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('reweighPieceCount'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('shipmentPieceCount'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('shipmentPalletCount'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('shipmentMotorPieceCount'),
      ReweighLogHeaderGridServiceColdefs.getColumnNumberDefinition('shipmentLoosePieceCount'),
      ReweighLogHeaderGridServiceColdefs._getYesNoOption('hazmatInd'),
      ReweighLogHeaderGridServiceColdefs._getYesNoOption('finalDeliveredInd'),
      ReweighLogHeaderGridServiceColdefs.getColumnDateDefinition('finalDeliveryDateTime'),
      ReweighLogHeaderGridServiceColdefs._getMoreInfoColDef(),
      ReweighLogHeaderGridServiceColdefs.getColumnDateDefinition('auditInfo.createdTimestamp'),
      ReweighLogHeaderGridServiceColdefs.getColumnDateDefinition('auditInfo.updatedTimestamp'),
    ];

    // remove displayInd|operator|operatorId
    if (!userRoleService.currentUserHasWriteAccess()) {
      const lRemovedFields: string[] = ['displayInd', 'operatorId', 'operatorFullName', 'moreInformationCd'];
      for (let i = 0; i < result.length; i++) {
        const colDef: any = result[i];
        if (lRemovedFields.includes(colDef.field)) {
          result.splice(i, 1);
          i--;
        }
      }
    }

    return result;
  }

  private static getColumnDateDefinition(property: string): ColDef {
    return {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName(property),
      width: 160,
      filter: false,
      menuTabs: [],
      field: property,
      valueGetter: (params: ValueGetterParams) => _get(params.data, property, -Infinity),
      valueFormatter: (params: ValueFormatterParams) => {
        const date: Date = _get(params.data, property);

        return (date && DateHelper.getPortandTzFormattedDate(date, ReweighLogDateFormats.MOMENT)) || '';
      },
    };
  }

  private static getColumnUsdAmountDefinition(property: string): ColDef {
    return {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName(property),
      field: property,
      cellClass: 'numeric-column',
      valueGetter: (params: ValueGetterParams) => _get(params.data, property, -Infinity),
      valueFormatter: (params: ValueFormatterParams) => {
        const value: number = _get(params.data, property);
        return (typeof value === 'number' && MiscUtil.formattedUsdAmount(value)) || '';
      },
      filter: 'xpoNumberFilterComponent',
    };
  }

  private static getColumnNumberDefinition(property: string): ColDef {
    return {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName(property),
      field: property,
      cellClass: 'numeric-column',
      valueGetter: (params: ValueGetterParams) => _get(params.data, property, -Infinity),
      valueFormatter: (params: ValueFormatterParams) => {
        const value: number = _get(params.data, property);
        return (typeof value === 'number' && formatNumber(value, 'en-US')) || '';
      },
      filter: 'xpoNumberFilterComponent',
    };
  }

  static _getYesNoOption(aProperty: string, aTooltip?: string): ColDef {
    const result: ColDef = {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName(aProperty),
      width: this.YES_NO_OPTION_COLUMN_WIDTH,
      cellClass: 'yes-no-column',
      tooltipField: aTooltip,
      cellRendererFramework: RwYesNoRendererComponent,
      valueGetter: (params: ValueGetterParams) =>
        YesNoOptionPipe.transformStatic(_get(params.data, aProperty, -Infinity)),
      field: aProperty,
    };
    return result;
  }

  static _getDisplayIndOptions(aProperty: string, aTooltip: string): ColDef {
    const result: ColDef = ReweighLogHeaderGridServiceColdefs._getYesNoOption(aProperty, aTooltip);
    result.cellRendererFramework = DisplayIndicatorRendererComponent;
    return result;
  }

  private static _getMoreInfoColDef(): ColDef {
    const FIELD: string = 'moreInformationCd';
    return {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName(FIELD),
      field: FIELD,
      width: 170,
      tooltipValueGetter: (params: ITooltipParams) => {
        let result: string = '';
        const logHeader: LogHeader = params.data;
        if (logHeader.moreInformationCd) {
          try {
            result = ReweighMoreInfoCdHelper.getCorrespondingLabel(logHeader.moreInformationCd);
          } catch (e) {
            result = logHeader.moreInformationCd;
          }
        }
        return result;
      },
    };
  }

  protected static getHeaderName(propertyName: string): string {
    const result: string = UiEntityUtil.getGridServiceHeaderName(propertyName, [
      ...UiEntities.COMMON_ENTITIES_FOR_LABELS.properties,
      ...UiEntities.LOG_HEADER.properties,
      ...UiEntities.LOG_HEADER_HIST.properties,
    ]);
    if (!result) {
      console.error('property with name:' + propertyName + ' was not found into the above properties result:' + result);
    }
    return result;
  }

  protected static getDefaultCol(propertyName: string, aWidth?: number): ColDef {
    return {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName(propertyName),
      field: propertyName,
      width: aWidth,
    };
  }

  protected static getDefaultColInitialHide(propertyName: string): ColDef {
    return {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName(propertyName),
      field: propertyName,
      initialHide: true,
    };
  }

  static _getStatusCdClass(aStatusCd: any) {
    if (aStatusCd) {
      return 'status-cd-' + aStatusCd;
    } else {
      return null;
    }
  }

  protected static _getExceptionCdDefinition(): ColDef {
    const FIELD: string = 'exceptionCd';
    return {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName(FIELD),
      field: FIELD,
      width: 110,
      cellRendererFramework: RwExceptionCdRendererComponent,
    };
  }

  protected static getOperatorFullName(): ColDef {
    const FIELD: string = 'operatorFullName';
    return {
      headerName: ReweighLogHeaderGridServiceColdefs.getHeaderName(FIELD),
      headerTooltip: 'Operator First name and Last name',
      width: Reweigh.OPERATOR_FULL_NAME_COL_WIDTH_PX,
      filter: 'agTextColumnFilter',
      field: FIELD,
    };
  }
}
