import { Pipe, PipeTransform } from '@angular/core';
import { YesNoOptionPipe } from '@shared/pipes';
import { YesNoEnum } from '@xpo-ltl/ngx-ltl';

@Pipe({ name: 'yesNoOptionOrNa' })
export class YesNoOptionPipeOrNa implements PipeTransform {
  static readonly DEFAULT_NULL_VALUE: string = 'N/A';

  static formatOrNa(src: boolean): string {
    let result: string = '';
    if (src === undefined || src === null) {
      result = YesNoOptionPipeOrNa.DEFAULT_NULL_VALUE;
    } else if (typeof src === 'boolean') {
      result = src ? YesNoEnum.Yes : YesNoEnum.No;
    } else {
      result = this.formatOrNa(!!src);
    }

    // console.debug('yesNoOptionPipe-> src:' + src + ' result:' + result);
    return result;
  }

  transform(option: boolean): string {
    return YesNoOptionPipeOrNa.formatOrNa(option);
  }
}
