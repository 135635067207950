import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExtendedLiftScaleCalibrationHeader } from '@shared/classes/entities/extended-lift-scale-calibration-header';
import { ExtendedLiftScaleCalibrationHeaderUniq } from '@shared/classes/entities/extended-lift-scale-calibration-header-uniq';
import { MiscUtil } from '@shared/classes/misc-util';
import { ScaleTestUtil } from '@shared/classes/utils/scale-test-util';
import { ScaleTestRouting } from '@shared/interfaces/scale-test-routing';
import { ReweighLogHeaderViewsService } from '@shared/services/reweigh-log-header-views.service';
import { UserRoleService } from '@shared/services/user-role';
import { UiEntityTableCellComponentBase } from '../../../../directives/ui-entity-table-cell-component-base';

@Component({
  selector: 'app-reweigh-log-scale-test-custom-uniq',
  templateUrl: './reweigh-log-scale-test-custom-uniq.component.html',
  styleUrls: ['./reweigh-log-scale-test-custom-uniq.component.scss'],
})
export class ReweighLogScaleTestCustomComponentUniq
  extends UiEntityTableCellComponentBase<ExtendedLiftScaleCalibrationHeaderUniq>
  implements OnInit {
  hasCurrentUserWriteAccess: boolean = false;

  constructor(
    protected router: Router,
    protected reweighLogHeaderViewsService: ReweighLogHeaderViewsService,
    protected userRoleService: UserRoleService
  ) {
    super();
    this.hasCurrentUserWriteAccess = this.userRoleService.currentUserHasWriteAccess();
  }

  ngOnInit(): void {}

  onScaleTestSearchClicked(aUiEntityData: ExtendedLiftScaleCalibrationHeaderUniq) {
    ScaleTestUtil.navigateToScaleTestsFor(
      ScaleTestRouting.buildFromExtendedLiftScaleCalibrationHeader(aUiEntityData),
      this.router
    );
    this.reweighLogHeaderViewsService.reweighLogHeaderViewsComponent.close();
  }

  getTestPassedIconName(uiEntityData: boolean): string {
    return MiscUtil.getTrueFalseIconName(uiEntityData);
  }
}
