import { IOperatorFirstnamLastname } from '@shared/classes/entities/operator-firstname-lastname';
import { UiEntityTableEntity } from '@shared/classes/entities/ui-entity-table-entity';
import { MiscUtil } from '@shared/classes/utils/misc-util';
import { ExtendedInspectionDimensionSrcCollection } from '@shared/enums/extended-inspection-dimension-src-collection';
import { InspectionDimension } from '@xpo-ltl/sdk-inspections';

export class ExtendedInspectionDimension extends InspectionDimension
  implements IOperatorFirstnamLastname, UiEntityTableEntity {
  dimensionsSourceTypeHumanReadable: string;
  operatorFullName: string;
  srcCollection: ExtendedInspectionDimensionSrcCollection;

  static toExtendedInspectionDimension(
    src: InspectionDimension,
    aSrcCollection: ExtendedInspectionDimensionSrcCollection
  ): ExtendedInspectionDimension {
    let result: ExtendedInspectionDimension = null;
    if (src) {
      result = new ExtendedInspectionDimension();
      Object.assign(result, src); // Object.assign(target, source)
      result.setExtendedData(aSrcCollection);
    }
    return result;
  }

  protected setExtendedData(aSrcCollection: ExtendedInspectionDimensionSrcCollection) {
    this.dimensionsSourceTypeHumanReadable = MiscUtil.upperCaseUnderscoredToWordsString(this.dimensionSourceTypeCd);
    this.setOperatorFullName();
    this.srcCollection = aSrcCollection;
  }

  setOperatorFullName() {
    if (this.employeeFirstName) {
      this.operatorFullName = this.employeeFirstName;
    }
    if (this.employeeLastName) {
      if (this.operatorFullName?.length > 0) {
        this.operatorFullName += ' ' + this.employeeLastName;
      }
    }
  }
}
