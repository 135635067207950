import { UiEntity } from '@shared/classes/entities/ui-entity';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { Reweigh } from '@shared/classes/reweigh';
import { UiEntityPropertyTypeEnum } from '@shared/enums/ui-entity-property-type-enum';

/**
 * To generate those, paste all properties from a given type into a /tmp/variables file, then run a bash
 * script like
 */
// $ cat ~/services/xpo-settings/scripts/gen-mat-table-rows.sh
// #!/bin/bash
//
//
// echo "******* UI ENTITY  ******************************"
// variables=$(grep ';' /tmp/variables)
// #For table
// while IFS= read -r var;do
//   var_name=$(echo "$var"|cut -d':' -f1|sed 's/^ *//;s/ *$//')
// var_type=$(echo "$var"|cut -d':' -f2|sed 's/^ *//;s/ *$//'|sed 's/;//g')
// res="new UiEntityProperty('$var_name', '$var_type'),\n"
// printf "%b" "$res"
// done <<< "$variables"

export class UiEntities {
  // AUDIT INFOS
  static readonly AUDIT_INFO_PROPERTIES: UiEntityProperty[] = [
    new UiEntityProperty('auditInfo.createdById', UiEntityPropertyTypeEnum.STRING, { label: 'Created By' }),
    new UiEntityProperty('auditInfo.createdTimestamp', UiEntityPropertyTypeEnum.DATE, { label: 'Create Tmst' }),
    new UiEntityProperty('auditInfo.createByPgmId', UiEntityPropertyTypeEnum.STRING, { label: 'Created Program ID' }),
    new UiEntityProperty('auditInfo.updateById', UiEntityPropertyTypeEnum.STRING, { label: 'Last Updated By' }),
    new UiEntityProperty('auditInfo.updatedTimestamp', UiEntityPropertyTypeEnum.DATE, { label: 'Update Tmst' }),
    new UiEntityProperty('auditInfo.updateByPgmId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Last Updated Program ID',
    }),
  ];

  // COMMON
  static readonly COMMON_ENTITIES_FOR_LABELS: UiEntity = new UiEntity('commonUiEntity', [
    new UiEntityProperty('histActionCd', UiEntityPropertyTypeEnum.STRING, {
      label: 'History Action Code',
      columnTooltip: 'Action Codes: I=Inserted, D=Deleted, U=Updated',
    }),
    new UiEntityProperty('histCreatedDateTime', UiEntityPropertyTypeEnum.DATE, { label: 'History Creation Date' }),
    new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, { label: 'Correlation ID' }),
    new UiEntityProperty('proNbr', UiEntityPropertyTypeEnum.STRING, { label: 'PRO' }),
    new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator',
      matTableColumnWidth: Reweigh.OPERATOR_FULL_NAME_COL_WIDTH_PX,
    }),
    new UiEntityProperty('shipmentInstId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Shipment Inst ID',
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, { label: 'SIC' }),
    new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, { label: 'Operator ID' }),
    new UiEntityProperty('reweighPieceCount', UiEntityPropertyTypeEnum.NUMBER, { label: 'Reweigh Piece Count' }),
    new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, { label: 'Reweigh Weight' }),
    ...UiEntities.AUDIT_INFO_PROPERTIES,
  ]);

  // LOG HEADER HIST
  static readonly LOG_HEADER_HIST: UiEntity = new UiEntity('LogHeaderHist', [
    new UiEntityProperty('logHeaderId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Reweigh ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 10,
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('proNbr', UiEntityPropertyTypeEnum.STRING, {
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 20,
    }),
    new UiEntityProperty('shipmentInstId', UiEntityPropertyTypeEnum.NUMBER, {
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('histCreatedDateTime', UiEntityPropertyTypeEnum.DATE, {
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 30,
    }),
    new UiEntityProperty('histActionCd', UiEntityPropertyTypeEnum.STRING, {
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 40,
    }),
    new UiEntityProperty('statusCd', UiEntityPropertyTypeEnum.STRING, {
      label: 'Status',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 50,
    }),
    new UiEntityProperty('exceptionCd', UiEntityPropertyTypeEnum.CUSTOM, {
      label: 'Exception Code',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 60,
    }),
    new UiEntityProperty('exceptionDescription', UiEntityPropertyTypeEnum.STRING, {
      label: 'Exception Description',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 70,
    }),
    new UiEntityProperty('imageCreatedInd', UiEntityPropertyTypeEnum.BOOLEAN, {
      label: 'Certificate',
      collapsedColumnIndex: 72,
      isVisibleInCollapsed: true,
    }),
    new UiEntityProperty('revenueChangeAmount', UiEntityPropertyTypeEnum.CURRENCY_USD, {
      label: 'Revenue Change Amount',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 75,
    }),
    new UiEntityProperty('hazmatInd', UiEntityPropertyTypeEnum.BOOLEAN, {
      label: 'Hazmat',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 77,
    }),
    new UiEntityProperty('correctionId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Correction ID',
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('revenueOriginAmount', UiEntityPropertyTypeEnum.CURRENCY_USD, {
      label: 'Revenue Original Amount',
    }),
    new UiEntityProperty('revenuePreviewAmount', UiEntityPropertyTypeEnum.CURRENCY_USD, {
      label: 'Revenue Preview Amount',
    }),
    new UiEntityProperty('revenueCorrAmount', UiEntityPropertyTypeEnum.CURRENCY_USD, {
      label: 'Revenue Corrected Amount',
    }),
    new UiEntityProperty('corrCommSequenceNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Corr. Com. Sequence Number',
    }),
    new UiEntityProperty('corrCommClass', UiEntityPropertyTypeEnum.STRING, {
      label: 'Corr. Com. Class',
    }),
    new UiEntityProperty('corrCommNmfcItem', UiEntityPropertyTypeEnum.STRING, {
      label: 'Corr. Com. NMFC Item',
    }),
    new UiEntityProperty('corrCommPieceCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Corr. Com. Piece Count',
    }),
    new UiEntityProperty('corrCommOriginWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Corr. Com. Original Weight',
    }),
    new UiEntityProperty('corrCommNewWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Corr. Com. New Weight',
    }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
      collapsedColumnIndex: 90,
      isVisibleInCollapsed: true,
    }),
    new UiEntityProperty('shipmentWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Shipment Weight',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 100,
    }),
    new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, {
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 110,
    }),
    new UiEntityProperty('weightChange', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Weight Change',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 120,
    }),
    new UiEntityProperty('appliedTolTypeCd', UiEntityPropertyTypeEnum.STRING, {
      label: 'Tolerance Applied',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 130,
    }),
    new UiEntityProperty('exceedsToleranceInd', UiEntityPropertyTypeEnum.BOOLEAN, {
      label: 'Exceeds Tolerance',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 140,
    }),
    new UiEntityProperty('appliedTolWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Tolerance Weight',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 150,
    }),
    // Expanded
    new UiEntityProperty('standardTolWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Standard Tolerance Weight',
    }),
    new UiEntityProperty('customerTolWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Cust. Tol. Weight',
    }),
    new UiEntityProperty('customerTolCustomerId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Cust. Tol. Customer Instance ID',
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('customerTolCustomerMadcode', UiEntityPropertyTypeEnum.STRING, {
      label: 'Cust. Tol. Customer MAD code',
    }),
    new UiEntityProperty('customerTolPartyTypeCd', UiEntityPropertyTypeEnum.STRING, {
      label: 'Cust. Tol. Party Type',
    }),
    new UiEntityProperty('customerTolParentTypeCd', UiEntityPropertyTypeEnum.STRING, {
      label: 'Cust. Tol. Parent Type',
    }),
    new UiEntityProperty('palletTolWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Pallet Tolerance Weight',
    }),
    new UiEntityProperty('palletTolCustomerMadcode', UiEntityPropertyTypeEnum.STRING, {
      label: 'Pallet Tol. Customer MAD Code',
    }),
    new UiEntityProperty('palletTolCustomerId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Pallet Tol. Customer Instance ID',
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('palletTolAgreementId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Pallet Tol. Agreement ID',
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('palletTolRuleset', UiEntityPropertyTypeEnum.STRING, {
      label: 'Pallet Tol. Ruleset',
    }),
    new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
      isVisibleInCollapsed: true,
      matTableColumnWidth: Reweigh.OPERATOR_FULL_NAME_COL_WIDTH_PX,
      collapsedColumnIndex: 170,
    }),
    new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, {
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 180,
    }),
    new UiEntityProperty('pltProInd', UiEntityPropertyTypeEnum.BOOLEAN, {
      label: 'Plt Ind',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 190,
    }),

    new UiEntityProperty('childProCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Child Pros',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 200,
    }),
    new UiEntityProperty('reweighPieceCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Reweigh Pieces',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 210,
    }),
    new UiEntityProperty('shipmentPieceCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Shipment Pieces',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 220,
    }),
    new UiEntityProperty('shipmentMotorPieceCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Motor Moves',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 230,
    }),
    new UiEntityProperty('shipmentPalletCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Pallets',
    }),
    new UiEntityProperty('shipmentLoosePieceCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Loose Pieces',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 240,
    }),
    new UiEntityProperty('moreInformationCd', UiEntityPropertyTypeEnum.STRING, {
      label: 'More Information Code',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 250,
    }),
    new UiEntityProperty('displayInd', UiEntityPropertyTypeEnum.BOOLEAN, {
      label: 'Display Ind',
    }),
    new UiEntityProperty('retryCount', UiEntityPropertyTypeEnum.STRING, {
      label: 'Retry Count',
    }),
    new UiEntityProperty('errorDetail', UiEntityPropertyTypeEnum.CUSTOM, {
      label: 'Error Details',
    }),
    new UiEntityProperty('sourceCreatedBy', UiEntityPropertyTypeEnum.STRING, {
      label: 'Created By',
    }),
    new UiEntityProperty('sourceCreatedDateTime', UiEntityPropertyTypeEnum.DATE, {
      label: 'Create Tmst',
    }),
    new UiEntityProperty('sourceCreatedProgramId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Update Tmst',
    }),
    new UiEntityProperty('sourceLastUpdateBy', UiEntityPropertyTypeEnum.STRING, {
      label: 'Updated By',
    }),
    new UiEntityProperty('sourceLastUpdateDateTime', UiEntityPropertyTypeEnum.DATE, {
      label: 'Update Tmst',
    }),
    new UiEntityProperty('sourceLastUpdateProgramId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Updated By',
    }),
    new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING),
  ]);

  // LOG_DETAIL
  static readonly LOG_DETAIL_HIST: UiEntity = new UiEntity('LogDetailHist', [
    new UiEntityProperty('logHeaderId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Log Header ID',
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('childProNbr', UiEntityPropertyTypeEnum.STRING, {
      label: 'Child PRO Number',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 10,
    }),
    new UiEntityProperty('histCreatedDateTime', UiEntityPropertyTypeEnum.DATE, {
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 20,
    }),
    new UiEntityProperty('histActionCd', UiEntityPropertyTypeEnum.STRING, {
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 30,
    }),
    new UiEntityProperty('reweighDateTimeLcl', UiEntityPropertyTypeEnum.DATE, {
      label: 'Reweigh Tmst Local',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 40,
    }),
    new UiEntityProperty('reweighDateTime', UiEntityPropertyTypeEnum.DATE, {
      label: 'Reweigh Tmst',
    }),
    new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Reweigh Weight',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 50,
    }),
    new UiEntityProperty('reweighPieceCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Reweigh Pieces Count',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 60,
    }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
      label: 'Sic Code',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 70,
    }),
    new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 80,
      matTableColumnWidth: Reweigh.OPERATOR_FULL_NAME_COL_WIDTH_PX,
    }),
    new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 90,
    }),
    new UiEntityProperty('vendorScaleId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Vendor Scale ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 100,
    }),
    new UiEntityProperty('equipmentFullId', UiEntityPropertyTypeEnum.CUSTOM, {
      label: 'Forklift ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 110,
    }),
    // expanded
    new UiEntityProperty('sourceCreatedBy', UiEntityPropertyTypeEnum.STRING, {
      label: 'Created By',
    }),
    new UiEntityProperty('sourceCreatedDateTime', UiEntityPropertyTypeEnum.DATE, {
      label: 'Created Tmst',
    }),
    new UiEntityProperty('sourceCreatedProgramId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Created Program ID',
    }),
    new UiEntityProperty('sourceLastUpdateBy', UiEntityPropertyTypeEnum.STRING, {
      label: 'Last Updated By',
    }),
    new UiEntityProperty('sourceLastUpdateDateTime', UiEntityPropertyTypeEnum.DATE, {
      label: 'Last Update Tmst',
    }),
    new UiEntityProperty('sourceLastUpdateProgramId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Last Update Program ID',
    }),
    new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Correlation ID',
    }),
    new UiEntityProperty('recordVersionNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Record version nbr',
    }),
    new UiEntityProperty('equipmentIdPrefix', UiEntityPropertyTypeEnum.STRING, {
      label: 'Equipment ID Prefix',
      isVisibleInExpanded: false,
    }),
    new UiEntityProperty('equipmentIdSuffixNbrString', UiEntityPropertyTypeEnum.STRING, {
      label: 'Equipment ID Suffix',
      isVisibleInExpanded: false,
    }),
  ]);

  // LOG_HEADER
  static readonly LOG_HEADER: UiEntity = new UiEntity('LogHeader', [
    new UiEntityProperty('logHeaderId', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('proNbr', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('shipmentInstanceId', UiEntityPropertyTypeEnum.NUMBER, { label: 'Shipment Instance ID' }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('statusCd', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('exceptionCd', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('exceptionDescription', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('childProCount', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('reweighPieceCount', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('shipmentPieceCount', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('shipmentLoosePieceCount', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('shipmentMotorPieceCount', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('shipmentWeight', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('correctionId', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('imageCreatedInd', UiEntityPropertyTypeEnum.BOOLEAN),
    new UiEntityProperty('palletTolAgreementId', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('palletTolRuleset', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('palletTolWeight', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('palletTolCustomerId', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('palletTolCustomerMadcode', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('customerTolCustomerId', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('customerTolCustomerMadcode', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('customerTolPartyTypeCd', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('customerTolParentTypeCd', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('customerTolWeight', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('standardTolWeight', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('appliedTolTypeCd', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('appliedTolWeight', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('corrCommSequenceNbr', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('corrCommClass', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('corrCommNmfcItem', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('corrCommPieceCount', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('corrCommOriginWeight', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('corrCommNewWeight', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('revenueOriginAmount', UiEntityPropertyTypeEnum.CURRENCY_USD),
    new UiEntityProperty('revenuePreviewAmount', UiEntityPropertyTypeEnum.CURRENCY_USD),
    new UiEntityProperty('revenueCorrAmount', UiEntityPropertyTypeEnum.CURRENCY_USD),
    new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, { label: 'Correlation ID' }),
    new UiEntityProperty('pltProInd', UiEntityPropertyTypeEnum.BOOLEAN),
    new UiEntityProperty('shipmentPalletCount', UiEntityPropertyTypeEnum.NUMBER),
    new UiEntityProperty('retryCount', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('displayInd', UiEntityPropertyTypeEnum.BOOLEAN),
    new UiEntityProperty('errorDetail', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('moreInformationCd', UiEntityPropertyTypeEnum.STRING),

    new UiEntityProperty('auditInfo', 'AuditInfo'),
    ...UiEntities.AUDIT_INFO_PROPERTIES,

    new UiEntityProperty('finalDeliveredInd', UiEntityPropertyTypeEnum.BOOLEAN, { label: 'Final Delivered' }),
    new UiEntityProperty('finalDeliveryDateTime', UiEntityPropertyTypeEnum.DATE, { label: 'Delivery Tmst' }),
    new UiEntityProperty('exceedsToleranceInd', UiEntityPropertyTypeEnum.BOOLEAN, { label: 'Exceeds Tolerance' }),
    new UiEntityProperty('weightChange', UiEntityPropertyTypeEnum.NUMBER, { label: 'Weight Change' }),
    new UiEntityProperty('revenueChangeAmount', UiEntityPropertyTypeEnum.CURRENCY_USD, {
      label: 'Revenue Change Amount',
    }),
    new UiEntityProperty('recreateCertificateInd', UiEntityPropertyTypeEnum.BOOLEAN),
    new UiEntityProperty('operatorFirstName', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('operatorLastName', UiEntityPropertyTypeEnum.STRING),
    new UiEntityProperty('logDetail', 'LogDetail[]'),
  ]);

  static readonly LOG_DETAIL: UiEntity = new UiEntity('LogDetail', [
    new UiEntityProperty('logHeaderId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Header Id',
      isVisibleInCollapsed: false, // default, but now clear ;)
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('childProNbr', UiEntityPropertyTypeEnum.STRING, {
      label: 'Child PRO Number',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 10,
    }),
    new UiEntityProperty('reweighDateTimeLcl', UiEntityPropertyTypeEnum.DATE, {
      label: 'Reweigh Tmst Local',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 20,
    }),
    new UiEntityProperty('reweighDateTime', UiEntityPropertyTypeEnum.DATE, {
      label: 'Reweigh Tmst',
    }),
    new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Reweigh Weight',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 30,
    }),
    new UiEntityProperty('reweighPieceCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Reweigh Piece Count',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 40,
    }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
      label: 'SIC',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 50,
    }),
    new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator',
      collapsedColumnIndex: 60,
      isVisibleInCollapsed: true,
      matTableColumnWidth: Reweigh.OPERATOR_FULL_NAME_COL_WIDTH_PX,
    }),
    new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator ID',
      collapsedColumnIndex: 70,
      isVisibleInCollapsed: true,
    }),
    new UiEntityProperty('vendorScaleId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Vendor Scale ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 80,
    }),
    new UiEntityProperty('equipmentFullId', UiEntityPropertyTypeEnum.CUSTOM, {
      label: 'Forklift ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 90,
    }),
    // expanded
    ...UiEntities.AUDIT_INFO_PROPERTIES,
    new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Correlation ID',
    }),
    new UiEntityProperty('equipmentIdPrefix', UiEntityPropertyTypeEnum.STRING, {
      label: 'Equipment ID Prefix',
      isVisibleInExpanded: false,
    }),
    new UiEntityProperty('equipmentIdSuffixNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Equipment ID Suffix',
      isVisibleInExpanded: false,
      isNumberNotFormatted: true,
    }),
  ]);

  static readonly LOG_DETAIL_FOR_DISPUTES_SUMMARY: UiEntity = new UiEntity('LogDetailForCustomSummaryView', [
    new UiEntityProperty('childProNbr', UiEntityPropertyTypeEnum.STRING, {
      label: 'Child PRO Number',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 10,
    }),
    new UiEntityProperty('reweighDateTimeLcl', UiEntityPropertyTypeEnum.DATE, {
      label: 'Reweigh Tmst Local',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 20,
    }),
    new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Reweigh Weight',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 30,
    }),
    new UiEntityProperty('reweighPieceCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Reweigh Piece Count',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 40,
    }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
      label: 'SIC',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 50,
    }),
    new UiEntityProperty('vendorScaleId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Vendor Scale ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 60,
    }),
    new UiEntityProperty('equipmentFullId', UiEntityPropertyTypeEnum.CUSTOM, {
      label: 'Forklift ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 70,
    }),

    // expanded
    new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator',
      matTableColumnWidth: Reweigh.OPERATOR_FULL_NAME_COL_WIDTH_PX,
      isVisibleInCollapsed: false,
      collapsedColumnIndex: 80,
    }),
    new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator ID',
      isVisibleInCollapsed: false,
      collapsedColumnIndex: 90,
    }),
    // end expanded
  ]);

  static readonly LIFT_SCALE_CALIBRATION_HEADER: UiEntity = new UiEntity('ExtendedLiftScaleCalibrationHeader', [
    new UiEntityProperty('calibrationHeaderId', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Calibration Header ID',
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('childProNbr', UiEntityPropertyTypeEnum.STRING, {
      label: 'Child PRO Number',
      isVisibleInCollapsed: false,
      collapsedColumnIndex: 8,
    }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
      label: 'SIC',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 10,
    }),
    new UiEntityProperty('vendorScaleId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Scale ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 20,
    }),
    new UiEntityProperty('equipmentFullId', UiEntityPropertyTypeEnum.CUSTOM, {
      label: 'Forklift ID',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 30,
    }),
    new UiEntityProperty('reweighDateTimeLcl', UiEntityPropertyTypeEnum.DATE, {
      label: 'Reweigh Tmst Local',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 40,
    }),
    new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Reweigh Weight',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 50,
    }),
    new UiEntityProperty('standardWeightDiff', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Standard Weight Diff',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 60,
    }),
    new UiEntityProperty('testPassedInd', UiEntityPropertyTypeEnum.CUSTOM, {
      label: 'Test Passed',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 70,
    }),
    new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator Full Name',
    }),
    new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, {
      label: 'OperatorId',
    }),
    new UiEntityProperty('reweighDateTime', UiEntityPropertyTypeEnum.DATE, {
      label: 'Reweigh Tmst',
    }),
    new UiEntityProperty('operatorFirstName', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator first name',
      isVisibleInExpanded: false,
    }),
    new UiEntityProperty('operatorLastName', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator last name',
      isVisibleInExpanded: false,
    }),
    ...UiEntities.AUDIT_INFO_PROPERTIES,
    new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, {
      label: 'Correlation ID',
    }),
    new UiEntityProperty('recordVersionNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Record version nbr',
      isNumberNotFormatted: true,
    }),
    new UiEntityProperty('equipmentIdPrefix', UiEntityPropertyTypeEnum.STRING, {
      label: 'Equipment ID Prefix',
      isVisibleInExpanded: false,
    }),
    new UiEntityProperty('equipmentIdSuffixNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Equipment ID Suffix',
      isVisibleInExpanded: false,
    }),
  ]);

  static readonly LIFT_SCALE_CALIBRATION_HEADER_FOR_LOG_DETAILS: UiEntity = new UiEntity(
    'ExtendedLiftScaleCalibrationHeader',
    [
      new UiEntityProperty('firstColumnId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Scale Test',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 6,
      }),
      new UiEntityProperty('calibrationHeaderId', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Calibration Header ID',
        isNumberNotFormatted: true,
      }),
      new UiEntityProperty('childProNbr', UiEntityPropertyTypeEnum.STRING, {
        label: 'Child PRO Number',
        collapsedColumnIndex: 8,
      }),
      new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
        label: 'SIC',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 10,
      }),
      new UiEntityProperty('vendorScaleId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Scale ID',
        collapsedColumnIndex: 20,
      }),
      new UiEntityProperty('equipmentFullId', UiEntityPropertyTypeEnum.CUSTOM, {
        label: 'Forklift ID',
        collapsedColumnIndex: 30,
      }),
      new UiEntityProperty('reweighDateTimeLcl', UiEntityPropertyTypeEnum.DATE, {
        label: 'Reweigh Tmst Local',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 40,
      }),
      new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Reweigh Weight',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 50,
      }),
      new UiEntityProperty('standardWeightDiff', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Standard Weight Diff',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 60,
      }),
      new UiEntityProperty('testPassedInd', UiEntityPropertyTypeEnum.CUSTOM, {
        label: 'Test Passed',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 70,
      }),
      new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator Full Name',
      }),
      new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, {
        label: 'OperatorId',
      }),
      new UiEntityProperty('reweighDateTime', UiEntityPropertyTypeEnum.DATE, {
        label: 'Reweigh Tmst',
      }),
      new UiEntityProperty('operatorFirstName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator first name',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('operatorLastName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator last name',
        isVisibleInExpanded: false,
      }),
      ...UiEntities.AUDIT_INFO_PROPERTIES,
      new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Correlation ID',
      }),
      new UiEntityProperty('recordVersionNbr', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Record version nbr',
        isNumberNotFormatted: true,
      }),
      new UiEntityProperty('equipmentIdPrefix', UiEntityPropertyTypeEnum.STRING, {
        label: 'Equipment ID Prefix',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('equipmentIdSuffixNbr', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Equipment ID Suffix',
        isVisibleInExpanded: false,
      }),
    ]
  );

  static readonly LIFT_SCALE_CALIBRATION_HEADER_FOR_DISPUTES: UiEntity = new UiEntity(
    'ExtendedLiftScaleCalibrationHeader',
    [
      new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
        label: 'SIC',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 10,
      }),
      new UiEntityProperty('vendorScaleId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Vendor Scale ID',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 20,
      }),
      new UiEntityProperty('equipmentFullId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Forklift ID',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 30,
      }),
      new UiEntityProperty('reweighDateTimeLcl', UiEntityPropertyTypeEnum.DATE, {
        label: 'Reweigh Tmst Local',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 50,
      }),
      new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Reweigh Weight',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 60,
      }),
      new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator',
        matTableColumnWidth: Reweigh.OPERATOR_FULL_NAME_COL_WIDTH_PX,
        isVisibleInCollapsed: false,
      }),
      new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, {
        label: 'OperatorId',
        isVisibleInCollapsed: false,
      }),
      new UiEntityProperty('equipmentIdPrefix', UiEntityPropertyTypeEnum.STRING, {
        label: 'Equipment ID Prefix',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('equipmentIdSuffixNbr', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Equipment ID Suffix',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('calibrationHeaderId', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Calibration Header ID',
        isVisibleInExpanded: false,
        isNumberNotFormatted: true,
      }),
      new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Correlation ID',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('testPassedInd', UiEntityPropertyTypeEnum.CUSTOM, {
        label: 'Test Passed',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('standardWeightDiff', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Standard Weight Diff',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('reweighDateTime', UiEntityPropertyTypeEnum.DATE, {
        label: 'Reweigh Tmst',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('recordVersionNbr', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Record version nbr',
        isVisibleInExpanded: false,
        isNumberNotFormatted: true,
      }),
      new UiEntityProperty('operatorFirstName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator first name',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('operatorLastName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator last name',
        isVisibleInExpanded: false,
      }),
      // ...UiEntities.auditInfoProperties,
    ]
  );

  static readonly EXTENDED_INSPECTION_DIMENSION: UiEntity = new UiEntity('ExtendedInspectionDimension', [
    new UiEntityProperty('srcCollection', UiEntityPropertyTypeEnum.STRING, {
      label: 'Source Collection',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 8,
    }),
    new UiEntityProperty('dimensionsSourceTypeHumanReadable', UiEntityPropertyTypeEnum.STRING, {
      label: 'Source',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 10,
    }),
    new UiEntityProperty('proNbr', UiEntityPropertyTypeEnum.STRING, {
      label: 'Pro Nbr',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 20,
    }),
    new UiEntityProperty('pieceCount', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Pieces',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 25,
    }),
    new UiEntityProperty('dimension.length', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Length',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 30,
    }),
    new UiEntityProperty('dimension.width', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Width',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 40,
    }),
    new UiEntityProperty('dimension.height', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Height',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 50,
    }),
    new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
      label: 'SIC',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 70,
    }),
    new UiEntityProperty('commoditySequenceNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Commodity SeqNbr',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 80,
    }),
    new UiEntityProperty('dimensionSequenceNbr', UiEntityPropertyTypeEnum.NUMBER, {
      label: 'Dimension SeqNbr',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 90,
    }),
    new UiEntityProperty('capturedTimestamp', UiEntityPropertyTypeEnum.DATE, {
      label: 'Captured Tmst',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 100,
    }),
    new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator Full Name',
      isVisibleInCollapsed: true,
      collapsedColumnIndex: 110,
    }),
    new UiEntityProperty('capturedById', UiEntityPropertyTypeEnum.STRING, {
      label: 'Operator ID',
    }),
    new UiEntityProperty('employeeFirstName', UiEntityPropertyTypeEnum.STRING, {
      label: 'First Name',
    }),
    new UiEntityProperty('employeeLastName', UiEntityPropertyTypeEnum.STRING, {
      label: 'Last Name',
    }),
  ]);

  static NOT_FORMATTED_NUMBER_COLUMN_NAMES: string[] = [
    'shipmentInstId',
    'logHeaderId',
    'correlationId',
    'correctionId',
  ];

  static readonly LIFT_SCALE_CALIBRATION_HEADER_UNIQ: UiEntity = new UiEntity(
    'ExtendedLiftScaleCalibrationHeaderUniq',
    [
      new UiEntityProperty('calibrationHeaderId', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Calibration Header ID',
        isNumberNotFormatted: true,
        isVisibleInCollapsed: false,
      }),
      new UiEntityProperty('childProNbr', UiEntityPropertyTypeEnum.STRING, {
        label: 'Child PRO Number',
        isVisibleInCollapsed: false,
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
        label: 'SIC',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 10,
      }),
      new UiEntityProperty('vendorScaleId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Scale ID',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 20,
      }),
      new UiEntityProperty('equipmentFullId', UiEntityPropertyTypeEnum.CUSTOM, {
        label: 'Forklift ID',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 30,
      }),
      new UiEntityProperty('reweighDateTimeLcl', UiEntityPropertyTypeEnum.DATE, {
        label: 'Reweigh Tmst Local',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 40,
      }),
      new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Reweigh Weight',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 50,
      }),
      new UiEntityProperty('standardWeightDiff', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Standard Weight Diff',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 60,
      }),
      new UiEntityProperty('testPassedInd', UiEntityPropertyTypeEnum.CUSTOM, {
        label: 'Test Passed',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 70,
      }),
      new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator Full Name',
      }),
      new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, {
        label: 'OperatorId',
      }),
      new UiEntityProperty('reweighDateTime', UiEntityPropertyTypeEnum.DATE, {
        label: 'Reweigh Tmst',
      }),
      new UiEntityProperty('operatorFirstName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator first name',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('operatorLastName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator last name',
        isVisibleInExpanded: false,
      }),
      ...UiEntities.AUDIT_INFO_PROPERTIES,
      new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Correlation ID',
      }),
      new UiEntityProperty('recordVersionNbr', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Record version nbr',
        isNumberNotFormatted: true,
      }),
      new UiEntityProperty('equipmentIdPrefix', UiEntityPropertyTypeEnum.STRING, {
        label: 'Equipment ID Prefix',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('equipmentIdSuffixNbr', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Equipment ID Suffix',
        isVisibleInExpanded: false,
      }),
    ]
  );

  static readonly LIFT_SCALE_CALIBRATION_HEADER_FOR_DISPUTES_UNIQ: UiEntity = new UiEntity(
    'ExtendedLiftScaleCalibrationHeaderUniq',
    [
      new UiEntityProperty('sicCode', UiEntityPropertyTypeEnum.STRING, {
        label: 'SIC',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 10,
      }),
      new UiEntityProperty('vendorScaleId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Vendor Scale ID',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 20,
      }),
      new UiEntityProperty('equipmentFullId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Forklift ID',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 30,
      }),
      new UiEntityProperty('reweighDateTimeLcl', UiEntityPropertyTypeEnum.DATE, {
        label: 'Reweigh Tmst Local',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 50,
      }),
      new UiEntityProperty('reweighWeight', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Reweigh Weight',
        isVisibleInCollapsed: true,
        collapsedColumnIndex: 60,
      }),
      new UiEntityProperty('operatorFullName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator',
        matTableColumnWidth: Reweigh.OPERATOR_FULL_NAME_COL_WIDTH_PX,
        isVisibleInCollapsed: false,
      }),
      new UiEntityProperty('operatorId', UiEntityPropertyTypeEnum.STRING, {
        label: 'OperatorId',
        isVisibleInCollapsed: false,
      }),
      new UiEntityProperty('equipmentIdPrefix', UiEntityPropertyTypeEnum.STRING, {
        label: 'Equipment ID Prefix',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('equipmentIdSuffixNbr', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Equipment ID Suffix',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('calibrationHeaderId', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Calibration Header ID',
        isVisibleInExpanded: false,
        isNumberNotFormatted: true,
      }),
      new UiEntityProperty('correlationId', UiEntityPropertyTypeEnum.STRING, {
        label: 'Correlation ID',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('testPassedInd', UiEntityPropertyTypeEnum.CUSTOM, {
        label: 'Test Passed',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('standardWeightDiff', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Standard Weight Diff',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('reweighDateTime', UiEntityPropertyTypeEnum.DATE, {
        label: 'Reweigh Tmst',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('recordVersionNbr', UiEntityPropertyTypeEnum.NUMBER, {
        label: 'Record version nbr',
        isVisibleInExpanded: false,
        isNumberNotFormatted: true,
      }),
      new UiEntityProperty('operatorFirstName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator first name',
        isVisibleInExpanded: false,
      }),
      new UiEntityProperty('operatorLastName', UiEntityPropertyTypeEnum.STRING, {
        label: 'Operator last name',
        isVisibleInExpanded: false,
      }),
      // ...UiEntities.auditInfoProperties,
    ]
  );
}
