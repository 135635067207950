import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { OperatorLogDetailHist } from '@shared/classes/entities/operator-log-detail-hist';
import { UiEntities } from '@shared/classes/entities/ui-entities';
import { UiEntity } from '@shared/classes/entities/ui-entity';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { AgGridUtil } from '@shared/classes/utils/ag-grid-util';
import { UiEntityMatTableComponent } from '@shared/components/ui-entity-mat-table/ui-entity-mat-table-component';
import { CollapsableExpandableComponent } from '@shared/enums/collapsable-expandable-component';
import { ConstantsService } from '@shared/services/constants';
import { ReweighLogHeadersGridService } from '@shared/services/reweigh-log-headers-grid';
import { ReweighLogHeaderGridServiceColdefs } from '@shared/services/reweigh-log-headers-grid/reweigh-log-header-grid-service-coldefs';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { UserRoleService } from '@shared/services/user-role';
import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';
import { UiEntityTableCellDirective } from '../../../directives/ ui-entity-table-cell-directive';
import { ReweighLogDetailHistTableCustomComponent } from '../mat-table-cell-projections/reweigh-log-detail-hist-table-custom/reweigh-log-detail-hist-table-custom.component';

@Component({
  selector: 'app-reweigh-log-detail-hist',
  templateUrl: '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.html',
  styleUrls: [
    '../lib/css/reweigh-log-table-section.scss',
    '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: ReweighLogDetailHistComponent, useExisting: UiEntityMatTableComponent }],
})
export class ReweighLogDetailHistComponent extends UiEntityMatTableComponent<
  OperatorLogDetailHist,
  ReweighLogDetailHistTableCustomComponent
> {
  @Input() collapserExpander: CollapsableExpandableComponent;

  /**
   * Cant be pushed on the parent
   * @param content
   */
  @ViewChild(MatSort, { static: false }) set content(content: ElementRef) {
    this.tableSort = content;
    if (this.tableSort) {
      this.dataSource.sort = this.tableSort;
    }
  }

  @ViewChildren(UiEntityTableCellDirective) uiEntityTableCellDirectiveViewChildren: QueryList<
    UiEntityTableCellDirective<OperatorLogDetailHist>
  >;

  @Input() logHeaderId: number;

  constructor(
    protected constantsService: ConstantsService,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    protected changeDetectorRefs: ChangeDetectorRef,
    protected userRoleService: UserRoleService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected reweighLogSummaryService: ReweighLogSummaryService
  ) {
    super(reweighLogSummaryService, changeDetectorRefs);
    // this.isTitleSectionDisplayed = true;
  }

  getComponentFactoryToProject(): ComponentFactory<ReweighLogDetailHistTableCustomComponent> {
    // tslint:disable-next-line:max-line-length
    const lComponentFactoryToProject: ComponentFactory<ReweighLogDetailHistTableCustomComponent> = this.componentFactoryResolver.resolveComponentFactory<
      ReweighLogDetailHistTableCustomComponent
    >(ReweighLogDetailHistTableCustomComponent);
    return lComponentFactoryToProject;
  }

  onLoadClicked(anEvent) {
    this.reweighApiServiceWrapper.listHistoryForLogDetails(this.logHeaderId).subscribe(
      (response: OperatorLogDetailHist[]) => {
        this.dataSource.data = response;
        this.dataIsLoaded = true;
        setTimeout(() => {
          this.isTitleSectionDisplayed = false;
        });
        // each time one element with that directive has been inserted into the DOM, then do...
        this.uiEntityTableCellDirectiveViewChildren.changes.subscribe((aUiEntityTableCellDirectiveQryList: any) => {
          // note that we receive aUiEntityTableCellDirectiveQryList as argument,
          // but its the same as this.uiEntityTableCellDirectiveViewChildren so we can use that one as well
          setTimeout(() => {
            this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
          });
        });
        this.changeDetectorRefs.detectChanges();
      },
      (error) => {
        // should already be caught by service wrapper
        console.error('onLoadClicked error loading');
      }
    );
  }

  getElementTableHeaderTitle(aUiEntityProperty: UiEntityProperty): string {
    const agGridColumnDefs: XpoAgGridBoardColumn[] = [
      ...ReweighLogHeaderGridServiceColdefs.getPrimaryColumns(this.userRoleService),
      ...ReweighLogHeadersGridService.secondaryColumns,
    ];
    const result: string = AgGridUtil.getHeaderNameFromColumnDefOrUiEntityDef(
      aUiEntityProperty,
      agGridColumnDefs,
      [...UiEntities.COMMON_ENTITIES_FOR_LABELS.properties, ...this.getUiEntity().properties],
      true
    );
    return result;
  }

  getUiEntity(): UiEntity {
    return UiEntities.LOG_DETAIL_HIST;
  }

  getMainTitle(): string {
    return 'Log Detail History for header: ' + this.logHeaderId;
  }

  isLoadDataButtonDisplayed(): boolean {
    return true;
  }

  getSortDefaultColumnName(): string {
    return 'histCreatedDateTime';
  }

  getCollapserExpander(): CollapsableExpandableComponent | undefined {
    return this.collapserExpander;
  }
}
