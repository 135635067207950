<ng-container [ngSwitch]="uiEntityProperty.propertyName">
  <!--    testPassed-->
  <ng-container *ngSwitchCase="'testPassedInd'">
    <xpo-icon [iconName]="getTestPassedIconName(uiEntityData[uiEntityProperty.propertyName])"></xpo-icon>
  </ng-container>
  <!--    equipmentFullId-->
  <ng-container *ngSwitchCase="'equipmentFullId'">
    <ng-template [ngIf]="hasCurrentUserWriteAccess" [ngIfElse]="valueOnly">
      <a
        xpo-link
        matTooltip="Click to go to scale tests for this scale"
        (click)="onScaleTestSearchClicked(uiEntityData)"
      >
        {{ uiEntityData[uiEntityProperty.propertyName] }}
      </a>
    </ng-template>
    <ng-template #valueOnly>
      {{ uiEntityData[uiEntityProperty.propertyName] }}
    </ng-template>
  </ng-container>
  <!--    For other fieldnames-->
  <ng-container *ngSwitchDefault>
    {{ uiEntityData[uiEntityProperty.propertyName] }}
  </ng-container>
</ng-container>
