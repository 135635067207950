import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ExtendedLiftScaleCalibrationHeaderUniq } from '@shared/classes/entities/extended-lift-scale-calibration-header-uniq';
import { UiEntities } from '@shared/classes/entities/ui-entities';
import { UiEntity } from '@shared/classes/entities/ui-entity';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { UiEntityMatTableComponent } from '@shared/components/ui-entity-mat-table/ui-entity-mat-table-component';
import { CollapsableExpandableComponent } from '@shared/enums/collapsable-expandable-component';
import { ConstantsService } from '@shared/services/constants';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import { ReweighApiServiceWrapper } from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { UserRoleService } from '@shared/services/user-role';
import { List } from 'immutable';
import { UiEntityTableCellDirective } from '../../../directives/ ui-entity-table-cell-directive';
import { ReweighLogScaleTestCustomComponentUniq } from '../mat-table-cell-projections/reweigh-log-scale-test-custom-uniq/reweigh-log-scale-test-custom.component';

@Component({
  selector: 'app-reweigh-log-scale-tests-uniq',
  templateUrl: '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.html',
  styleUrls: [
    '../lib/css/reweigh-log-table-section.scss',
    '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,//todo for future when loading is working correctly
  // for @viewChild and inputs to work with inheritence
  providers: [{ provide: ReweighLogScaleTestsUniqComponent, useExisting: UiEntityMatTableComponent }],
})
export class ReweighLogScaleTestsUniqComponent
  extends UiEntityMatTableComponent<ExtendedLiftScaleCalibrationHeaderUniq, ReweighLogScaleTestCustomComponentUniq>
  implements AfterViewInit {
  protected CHILD_PRO_NUMBER_EMPTY_DEFAULT_VALUE: string = 'N/A';

  @Input() collapserExpander: CollapsableExpandableComponent;

  /**
   * Cant be pushed on the parent
   * @param content
   */
  @ViewChild(MatSort, { static: false }) set content(content: ElementRef) {
    this.tableSort = content;
    if (this.tableSort) {
      this.dataSource.sort = this.tableSort;
    }
  }

  @ViewChildren(UiEntityTableCellDirective) uiEntityTableCellDirectiveViewChildren: QueryList<
    UiEntityTableCellDirective<ExtendedLiftScaleCalibrationHeaderUniq>
  >;

  @Input() logHeaderId: number;

  @Input()
  tableData: List<ExtendedLiftScaleCalibrationHeaderUniq>;

  @Input() isHeaderTitleDisplayedInput: boolean = true;
  @Input() isFullTableDisplayed: boolean = true;

  constructor(
    protected constantsService: ConstantsService,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    protected changeDetectorRefs: ChangeDetectorRef,
    private dialog: MatDialog,
    private userRoleService: UserRoleService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected reweighLogSummaryService: ReweighLogSummaryService
  ) {
    super(reweighLogSummaryService, changeDetectorRefs);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.dataSource.data = this.tableData.toArray();
    this.dataIsLoaded = true;
    this.changeDetectorRefs.detectChanges();
    setTimeout(() => {
      this.isTitleSectionDisplayed = this.isHeaderTitleDisplayedInput;
      // load component projection
      this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
    });
    // each time one element with that directive has been inserted into the DOM, then do...
    this.uiEntityTableCellDirectiveViewChildren.changes.subscribe((aUiEntityTableCellDirectiveQryList: any) => {
      // note that we receive aUiEntityTableCellDirectiveQryList as argument,
      // but its the same as this.uiEntityTableCellDirectiveViewChildren so we can use that one as well
      setTimeout(() => {
        this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
      });
    });
  }

  getComponentFactoryToProject(): ComponentFactory<ReweighLogScaleTestCustomComponentUniq> {
    // tslint:disable-next-line:max-line-length
    const lComponentFactoryToProject: ComponentFactory<ReweighLogScaleTestCustomComponentUniq> = this.componentFactoryResolver.resolveComponentFactory<
      ReweighLogScaleTestCustomComponentUniq
    >(ReweighLogScaleTestCustomComponentUniq);
    return lComponentFactoryToProject;
  }

  getUiEntity(): UiEntity {
    // tslint:disable-next-line:no-shadowed-variable
    let result: UiEntity;
    if (this.isFullTableDisplayed) {
      result = UiEntities.LIFT_SCALE_CALIBRATION_HEADER_UNIQ;
    } else {
      result = UiEntities.LIFT_SCALE_CALIBRATION_HEADER_FOR_DISPUTES_UNIQ;
    }
    return result;
  }

  getMainTitle(): string {
    return 'Lift Scale Tests';
  }

  isLoadDataButtonDisplayed(): boolean {
    return false;
  }

  getSortDefaultColumnName(): string {
    return 'vendorScaleId';
  }

  /**
   * Override
   */
  // getVisibleColumns(): string[] {
  //   const result: string[] = super.getVisibleColumns();
  //   if (!this.userRoleService.currentUserHasWriteAccess()) {
  //     const lRemovedFields: string[] = ['operatorId', 'operatorFullName'];
  //     for (let i = 0; i < result.length; i++) {
  //       const colName: string = result[i];
  //       if (lRemovedFields.includes(colName)) {
  //         result.splice(i, 1);
  //         i--;
  //       }
  //     }
  //   }
  //   console.debug('getVisColsUniq:', result)
  //   return result;
  // }

  /**
   * Override
   */
  getValueFromRowData(aRowData: any, aUiEntityProperty: UiEntityProperty): string {
    let result: string = '' + aRowData;
    let lShowDefaultData: boolean = true;
    if (aUiEntityProperty.propertyName === 'childProNbr') {
      if (!aRowData || ('' + aRowData).length === 0) {
        result = this.CHILD_PRO_NUMBER_EMPTY_DEFAULT_VALUE;
        lShowDefaultData = false;
      }
    }
    if (lShowDefaultData) {
      result = super.getValueFromRowData(aRowData, aUiEntityProperty);
    }

    return result;
  }

  getCollapserExpander(): CollapsableExpandableComponent | undefined {
    return this.collapserExpander;
  }
}
