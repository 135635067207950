/**
 * Generic class for custom errors
 */
export class ReweighError extends Error {
  protected cause: any;
  constructor(aMessage: string, aCause?: any) {
    super(aMessage); // call the parent constructor
    this.name = 'ReweighError'; // set the name property
    this.cause = aCause;
  }
}
