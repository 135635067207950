import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReweighLogHeadersFilterFormNames } from '@shared/enums';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import { ReweighService } from '@shared/services/reweigh-service/reweigh.service';
import { ShipmentDetailsService } from '@shared/services/shipment-details/shipment-details.service';
import { UserRoleService } from '@shared/services/user-role';
import { ReweighLogSummaryBase } from '../reweigh-log-common/lib/reweigh-log-summary-base';
import { DmsApiWrapper } from '../reweigh-log-summary/services/dms-api-wrapper.service';

@Component({
  selector: 'app-reweigh-log-summary2',
  templateUrl: './reweigh-log-summary2.component.html',
  styleUrls: ['../reweigh-log-summary/reweigh-log-summary.component.scss', './reweigh-log-summary2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'reweigh-log-summary-page',
  },
})
export class ReweighLogSummary2Component extends ReweighLogSummaryBase {
  constructor(
    protected dmsApiWrapper: DmsApiWrapper,
    protected shipmentDetailsService: ShipmentDetailsService,
    protected router: Router,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected dialog: MatDialog,
    protected reweighService: ReweighService,
    protected userRoleService: UserRoleService,
    protected reweighLogSummaryService: ReweighLogSummaryService,
    protected titleService: Title,
    protected changeDetection: ChangeDetectorRef
  ) {
    super(
      dmsApiWrapper,
      shipmentDetailsService,
      router,
      reweighAppNotificationService,
      dialog,
      reweighService,
      userRoleService,
      reweighLogSummaryService,
      titleService,
      changeDetection
    );
  }

  protected readonly ReweighLogHeadersFilterFormNames = ReweighLogHeadersFilterFormNames;
}
