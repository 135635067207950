import { ExtendedLiftScaleCalibrationHeader } from '@shared/classes/entities/extended-lift-scale-calibration-header';
import { LiftScaleCalibrationHeader } from '@xpo-ltl/sdk-reweigh';
import { ScaleForkliftReport } from '../../../reweigh-calibration-headers/lib/scale-forklift-report';

export class ExtendedLiftScaleCalibrationHeaderUniq extends ExtendedLiftScaleCalibrationHeader {
  operatorFullName: string;
  equipmentFullId: string;
  firstColumnId: string = '1';

  static toExtendedLiftScaleCalibrationHeaderUniq(
    src: LiftScaleCalibrationHeader
  ): ExtendedLiftScaleCalibrationHeaderUniq {
    let result: ExtendedLiftScaleCalibrationHeaderUniq = null;
    if (src) {
      result = new ExtendedLiftScaleCalibrationHeaderUniq();
      Object.assign(result, src); // Object.assign(target, source)
      result.setOperatorFullName();
      result.setFullEquipmentId();
    }
    return result;
  }

  setOperatorFullName() {
    if (this.operatorFirstName) {
      this.operatorFullName = this.operatorFirstName;
    }
    if (this.operatorLastName) {
      if (this.operatorFullName?.length > 0) {
        this.operatorFullName += ' ' + this.operatorLastName;
      }
    }
  }

  protected setFullEquipmentId() {
    this.equipmentFullId = ScaleForkliftReport.getEquipmentFullId(this.equipmentIdPrefix, this.equipmentIdSuffixNbr);
  }
}
