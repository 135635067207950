<div class="overall">
  <mat-accordion multi>
    <!--  SUMMARY-->
    <mat-expansion-panel class="main-card" [expanded]="true" xpoExpansionPanelDark>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ LogHeaderSummaryTitles.REWEIGH_SUMMARY }}
          &nbsp;
          <xpo-icon iconName="info" class="white-icon" matTooltip="Data on last update of Reweigh"></xpo-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <xpo-card class="sub-card">
          <xpo-card-content>
            <div class="line">
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.PRO_NBR }}</label>
                <span class="field_value">{{ extendedLogHeader?.proNbr }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.SIC }}</label>
                <span class="field_value">{{ extendedLogHeader?.sicCode }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.HEADER_ID }}</label>
                <span class="field_value">{{ extendedLogHeader?.logHeaderId | formatNumberOrNaPipe }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.STATUS }}</label>
                <span class="field_value">{{ extendedLogHeader?.statusCd }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.EXEPTION_DESC }}</label>
                <span class="field_value">{{
                  extendedLogHeader?.exceptionDescription || LogHeaderSummaryGroups.DEFAULT_NULL_VALUE
                }}</span>
              </div>
            </div>
            <!--          second line -->
            <div class="line">
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.PLT_PRO }}</label>
                <span class="field_value">{{ extendedLogHeader?.pltProInd | yesNoOptionOrNa }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.CHILD_PRO_COUNT }}</label>
                <span class="field_value">{{ extendedLogHeader?.childProCount | formatNumberOrNaPipe }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.SHIPMENT_PIECE_COUNT }}</label>
                <span class="field_value">{{
                  extendedLogHeader?.shipmentPieceCount || LogHeaderSummaryGroups.DEFAULT_NULL_VALUE
                }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.SHIPMENT_LOOSE_PIECES_COUNT }}</label>
                <span class="field_value">{{ extendedLogHeader?.shipmentLoosePieceCount | formatNumberOrNaPipe }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.SHIPMENT_MOTOR_PIECES_COUNT }}</label>
                <span class="field_value">{{ extendedLogHeader?.shipmentMotorPieceCount | formatNumberOrNaPipe }}</span>
              </div>
            </div>
            <!--          3rd line-->
            <div class="line">
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.SHIPMENT_WEIGHT }}</label>
                <span class="field_value">{{ extendedLogHeader?.shipmentWeight | formatNumberOrNaPipe }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.REWEIGH_WEIGHT }}</label>
                <span class="field_value">{{ extendedLogHeader?.reweighWeight | formatNumberOrNaPipe }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.WEIGHT_CHANGE }}</label>
                <span class="field_value">{{ extendedLogHeader?.weightChange | formatNumberOrNaPipe }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.EXCEEDS_TOL }}</label>
                <span class="field_value">{{ extendedLogHeader?.exceedsToleranceInd | yesNoOptionOrNa }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.REWEIGH_PIECE_COUNT }}</label>
                <span class="field_value">{{ extendedLogHeader?.reweighPieceCount | formatNumberOrNaPipe }}</span>
              </div>
            </div>
            <!--          4th line-->
            <div class="line">
              <div class="field">
                <label class="field_label">{{
                  LogHeaderSummaryTitles.APPLIED_TOLERANCE + ' ' + LogHeaderSummaryTitles.APPLIED_TOLERANCE_TYPE_CD
                }}</label>
                <span class="field_value">{{
                  extendedLogHeader?.appliedTolTypeCd || LogHeaderSummaryGroups.DEFAULT_NULL_VALUE
                }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{
                  LogHeaderSummaryTitles.APPLIED_TOLERANCE + ' ' + LogHeaderSummaryTitles.APPLIED_TOLERANCE_WEIGHT
                }}</label>
                <span class="field_value">{{ extendedLogHeader?.appliedTolWeight | formatNumberOrNaPipe }}</span>
              </div>
              <div class="field">
                <!--                empty-->
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.HAZMAT_IND }}</label>
                <span class="field_value">{{ extendedLogHeader?.hazmatInd | yesNoOptionOrNa }}</span>
              </div>
              <div class="field">
                <label class="field_label">{{ LogHeaderSummaryTitles.LST_UPDT_TMST }}</label>
                <span class="field_value">{{ extendedLogHeader?.auditInfo?.updatedTimestamp | portlandDateOrNa }}</span>
              </div>
            </div>
          </xpo-card-content>
        </xpo-card>
      </ng-template>
    </mat-expansion-panel>
    <!--  Shipment -->
    <mat-expansion-panel class="main-card detail-card" [expanded]="true" xpoExpansionPanelDark>
      <mat-expansion-panel-header>
        <mat-panel-title
          >{{ LogHeaderSummaryTitles.SHIPMENT }}
          &nbsp;
          <xpo-icon
            iconName="info"
            class="white-icon"
            matTooltip="Data of current Shipment related to Reweigh"
          ></xpo-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <xpo-card class="sub-card">
          <xpo-card-content>
            <div class="field">
              <label class="field_label">{{ LogHeaderSummaryTitles.PICKUP_DATE }}</label>
              <span class="field_value">{{
                logHeaderShipment?.pickupDate || LogHeaderSummaryGroups.DEFAULT_NULL_VALUE
              }}</span>
            </div>
            <div class="field">
              <label class="field_label">{{ LogHeaderSummaryTitles.FINAL_DELIVERED_IND }}</label>
              <span class="field_value">{{ logHeaderShipment?.finalDeliveredInd | yesNoOption }}</span>
            </div>
            <div class="field">
              <label class="field_label">{{ LogHeaderSummaryTitles.FINAL_DELIVERED_TMST }}</label>
              <span class="field_value">{{ logHeaderShipment?.finalDeliveryDateTime | portlandDateOrNa }}</span>
            </div>
            <div class="field">
              <label class="field_label">{{ LogHeaderSummaryTitles.SHIPMENT_MOTOR_PIECES_COUNT }}</label>
              <span class="field_value">{{ logHeaderShipment?.motorMovesNbr | formatNumberOrNaPipe }}</span>
            </div>
            <div class="field">
              <label class="field_label">{{ LogHeaderSummaryTitles.SHIPMENT_LOOSE_PIECES_COUNT }}</label>
              <span class="field_value">{{ logHeaderShipment?.loosePieceCount | formatNumberOrNaPipe }}</span>
            </div>
            <div class="field">
              <label class="field_label">{{ LogHeaderSummaryTitles.SHIPMENT_TOTAL_PIECES_COUNT }}</label>
              <span class="field_value">{{ logHeaderShipment?.totalPieceCount | formatNumberOrNaPipe }}</span>
            </div>
          </xpo-card-content>
        </xpo-card>
      </ng-template>
    </mat-expansion-panel>

    <!--  Details-->
    <mat-expansion-panel class="main-card detail-card" [expanded]="true" xpoExpansionPanelDark>
      <mat-expansion-panel-header>
        <mat-panel-title
          >Detail Records <span class="count-on-black-bg">{{ (logDetails$ | async).size }}</span></mat-panel-title
        >
        <mat-slide-toggle
          *ngIf="!(logDetails$ | async).isEmpty()"
          (click)="
            onCollapseExpandColumnsClicked($event, ExpandableCollapsableComponent.ReweighLogDetailsComponentDisputeSum)
          "
          >{{ ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT }}</mat-slide-toggle
        >
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div
          class="reweigh-header-record-details_no-records"
          *ngIf="(logDetails$ | async).isEmpty(); else showLogDetailsTpl"
        >
          <mat-icon>insert_drive_file</mat-icon>
          No records to show
        </div>
        <ng-template #showLogDetailsTpl>
          <reweigh-log-details
            class="reweigh-header-record-details"
            [tableDataAsList]="logDetails$ | async"
            [viewType]="ReweighLogDetailsViewType.DISPUTES"
            [isHeaderTitleDisplayedInput]="false"
            [collapserExpander]="ExpandableCollapsableComponent.ReweighLogDetailsComponentDisputeSum"
          ></reweigh-log-details>
        </ng-template>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
  <!--    LiftScale tests-->
  <mat-expansion-panel
    class="main-card detail-card"
    [expanded]="true"
    xpoExpansionPanelDark
    *ngIf="hasCurrentUserWriteAccess"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        >Scale Tests (Unique only)
        <span class="count-on-black-bg">{{ (liftScaleCalibrationHeadersUniq$ | async).size }}</span></mat-panel-title
      >
      <mat-slide-toggle
        *ngIf="!(liftScaleCalibrationHeadersUniq$ | async).isEmpty()"
        (click)="onCollapseExpandColumnsClicked($event, ExpandableCollapsableComponent.ScaleTestsUniqDisputeSum)"
        >{{ ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT }}</mat-slide-toggle
      >
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div
        class="reweigh-header-record-details_no-records"
        *ngIf="(liftScaleCalibrationHeadersUniq$ | async)?.isEmpty(); else showScaleTestsTpl"
      >
        <mat-icon>insert_drive_file</mat-icon>
        No records to show
      </div>
      <ng-template #showScaleTestsTpl>
        <div class="scrollable-auto">
          <app-reweigh-log-scale-tests-uniq
            class="reweigh-header-record-details"
            [tableData]="liftScaleCalibrationHeadersUniq$ | async"
            [isHeaderTitleDisplayedInput]="false"
            [isFullTableDisplayed]="true"
            [collapserExpander]="ExpandableCollapsableComponent.ScaleTestsUniqDisputeSum"
          ></app-reweigh-log-scale-tests-uniq>
        </div>
      </ng-template>
    </ng-template>
  </mat-expansion-panel>
  <!--    Dimensions-->
  <mat-expansion-panel
    class="main-card detail-card"
    [expanded]="true"
    xpoExpansionPanelDark
    *ngIf="hasCurrentUserWriteAccess"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        >Shipment Dimensions
        <span class="count-on-black-bg">{{ shipmentDimensionsCount$ | async }}</span></mat-panel-title
      >
      <mat-slide-toggle
        *ngIf="hasShipmentDimensions$ | async"
        (click)="onCollapseExpandColumnsClicked($event, ExpandableCollapsableComponent.ShipmentDimensionsDisputeSum)"
        >{{ ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT }}</mat-slide-toggle
      >
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div
        class="reweigh-header-record-details_no-records"
        *ngIf="!(hasShipmentDimensions$ | async); else showShmDimsTpl"
      >
        <mat-icon>insert_drive_file</mat-icon>
        No records to show
      </div>
      <ng-template #showShmDimsTpl>
        <div class="scrollable-auto">
          <app-shipment-dimensions
            [collapserExpander]="ExpandableCollapsableComponent.ShipmentDimensionsDisputeSum"
          ></app-shipment-dimensions>
        </div>
      </ng-template>
    </ng-template>
  </mat-expansion-panel>
</div>
