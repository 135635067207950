<div class="overall-component">
  <!--  <a (click)="toggleCollapseExpandData($event)"-->
  <!--    >Toggle for not working cols col:{{ visibleColumns?.length || 0 }} dataLength:{{ dataSource?.data?.length || 0 }}</a-->
  <!--  >-->
  <!--  Title -->
  <h2
    class="section-title"
    matTooltip="Click to load data"
    (click)="onLoadClicked($event)"
    *ngIf="isTitleSectionDisplayed"
  >
    <!--    Load data-->
    <ng-template *ngIf="isLoadDataButtonDisplayed()">
      <button
        mat-icon-button
        *ngIf="!dataIsLoaded"
        (click)="onLoadClicked($event)"
        matTooltip="Click to load data"
        class="expand-btn"
      >
        <mat-icon>add</mat-icon>
      </button>
    </ng-template>
    <!--    Title-->
    {{ getMainTitle() }}
    <!--    more/less data-->
    <div class="collapse-expand" *ngIf="dataSource.data?.length > 0">
      <a
        xpo-regular-link
        *ngIf="isTableColumnsExpanded; else showMorelinkTpl"
        (click)="toggleCollapseExpandData($event)"
      >
        Show Less Data
        <mat-icon>unfold_less</mat-icon>
      </a>
      <ng-template #showMorelinkTpl>
        <a xpo-regular-link (click)="toggleCollapseExpandData($event)"
          >Show More Data
          <mat-icon>unfold_more</mat-icon>
        </a>
      </ng-template>
    </div>
  </h2>
  <!--Table-->
  <div class="table-container">
    <div class="actions" *ngIf="false && dataSource.data?.length > 0">
      <button
        mat-icon-button
        class="expand-btn"
        (click)="toggleCollapseExpandData($event)"
        [matTooltip]="getMatTableMoreLessTooltip()"
      >
        <mat-icon *ngIf="!isTableColumnsExpanded">chevron_right</mat-icon>
        <mat-icon *ngIf="isTableColumnsExpanded">chevron_left</mat-icon>
      </button>
    </div>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      *ngIf="dataSource.data?.length > 0"
      [matSortActive]="getSortDefaultColumnName()"
      [matSortDirection]="getSortDefaultDirection()"
      class="ui-entity-expandable-data-table"
      multiTemplateDataRows
    >
      <!--      First Column -->
      <!-- Position Column -->
      <ng-container [matColumnDef]="EXPAND_COLLAPSE_COL_DEF_TITLE" *ngIf="isExpandableRowsEnabled">
        <th
          mat-header-cell
          *matHeaderCellDef
          (click)="toggleTableColumnsExpandedForExpandedRow($event)"
          matTooltip="Collapse/Expand Row Details"
        >
          <button
            mat-icon-button
            class="expand-table-btn"
            *ngIf="isExpandDetailRowsButtonVisible()"
            matTooltip="Click to expand/collapse details"
            (click)="toggleShowExpandedRow($event)"
          >
            <mat-icon *ngIf="!isExpandedRow">expand_more</mat-icon>
            <mat-icon *ngIf="isExpandedRow">expand_less</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            class="expand-table-btn"
            *ngIf="isExpandDetailRowsButtonVisible()"
            matTooltip="Click to expand/collapse details"
            (click)="toggleShowExpandedRow($event)"
          >
            <mat-icon *ngIf="!isExpandedRow">expand_more</mat-icon>
            <mat-icon *ngIf="isExpandedRow">expand_less</mat-icon>
          </button>
        </td>
      </ng-container>
      <!--      Table content with dynamic properties-->
      <ng-container
        *ngFor="let uiEntityProperty of uiEntityProperties; let colIndex = index"
        [matColumnDef]="uiEntityProperty.propertyName"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [matTooltip]="getElementTableHeaderTooltip(uiEntityProperty)"
          [ngClass]="getNgClassForHeaderAndCell(uiEntityProperty)"
        >
          {{ getElementTableHeaderTitle(uiEntityProperty) }}
        </th>
        <td mat-cell [ngClass]="getNgClassForHeaderAndCell(uiEntityProperty)" *matCellDef="let rowData">
          <ng-container [ngSwitch]="uiEntityProperty.variableType">
            <ng-container *ngSwitchCase="UiEntityPropertyTypeEnum.CUSTOM">
              <ng-template [rowData]="rowData" [uiEntityProperty]="uiEntityProperty" uiEntityTableCell></ng-template>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ getValueFromRowData(rowData, uiEntityProperty) }}
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- Expanded Content Column  -->
      <ng-container matColumnDef="expandedDetail" *ngIf="isExpandableRowsEnabled && isExpandedRow">
        <td
          mat-cell
          *matCellDef="let expandedEntity"
          [attr.colspan]="visibleColumns?.length"
          class="expanded-table-cell"
        >
          <div
            class="expanded-table-cell-content"
            [@detailExpand]="isExpandedRow && expandedEntity?.dataSource?.data?.length > 0 ? 'expanded' : 'collapsed'"
          >
            <div
              class="actions"
              *ngIf="expandedEntity?.dataSource?.data?.length > 0"
              (click)="toggleTableColumnsExpandedForExpandedRow($event)"
              [matTooltip]="getMatTableMoreLessTooltip()"
            >
              <button mat-icon-button class="expand-btn">
                <mat-icon *ngIf="!isTableColumnsExpandedForExpandedRow">chevron_right</mat-icon>
                <mat-icon *ngIf="isTableColumnsExpandedForExpandedRow">chevron_left</mat-icon>
              </button>
            </div>
            <table
              mat-table
              [dataSource]="expandedEntity.dataSource"
              matSort
              *ngIf="expandedEntity?.dataSource?.data?.length > 0"
              class="ui-entity-data-extended-table"
              multiTemplateDataRows
            >
              <!--   nested   table content-->
              <ng-container
                *ngFor="let expandedUiEntityProperty of expandedUiEntityProperties; let expandedColIndex = index"
                [matColumnDef]="expandedUiEntityProperty.propertyName"
              >
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [matTooltip]="getExpandedElementTableHeaderTooltip(expandedUiEntityProperty)"
                  [ngClass]="getNgClassForHeaderAndCell(expandedUiEntityProperty)"
                >
                  {{ getExpandedElementTitle(expandedUiEntityProperty) }}
                </th>
                <td
                  mat-cell
                  [ngClass]="getNgClassForHeaderAndCell(expandedUiEntityProperty)"
                  *matCellDef="let expandedRowData"
                >
                  <ng-container [ngSwitch]="expandedUiEntityProperty.variableType">
                    <ng-container *ngSwitchCase="UiEntityPropertyTypeEnum.CUSTOM">
                      <ng-container [ngSwitch]="expandedUiEntityProperty.propertyName">
                        <!--    testPassed-->
                        <ng-container *ngSwitchCase="'testPassedInd'">
                          <xpo-icon
                            [iconName]="
                              getTestPassedIconName(!!getValueFromRowData(expandedRowData, expandedUiEntityProperty))
                            "
                          ></xpo-icon>
                        </ng-container>
                        <!--    For other fieldnames-->
                        <ng-container *ngSwitchDefault>
                          {{ getValueFromRowData(expandedRowData, expandedUiEntityProperty) }}
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ getValueFromRowData(expandedRowData, expandedUiEntityProperty) }}
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
              <!--      headers-->
              <tr mat-header-row *matHeaderRowDef="expandedVisibleColumns; sticky: true"></tr>
              <tr
                mat-row
                *matRowDef="let expandedRow; columns: expandedVisibleColumns; let expandedRowIndex = dataIndex"
                [ngClass]="getNgClassForExpandedRow(expandedRowIndex)"
              ></tr>
            </table>
          </div>
        </td>
      </ng-container>

      <!--      headers-->
      <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
      <!--      Selection and hover-->
      <tr
        mat-row
        *matRowDef="let row; columns: visibleColumns; let dataIndex = dataIndex; let renderIndex = renderIndex"
        (click)="selection.toggle(row)"
        [ngClass]="getExpandedRowClass(dataIndex, renderIndex, row, selection)"
        (mouseover)="row.hovered = true"
        (mouseout)="row.hovered = false"
      ></tr>
      <!--      expanded rows-->
      <ng-container *ngIf="isExpandableRowsEnabled && isExpandedRow">
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']; let rowIndex = dataIndex"
          class="expanded-table-row"
        ></tr>
      </ng-container>
      <!--      If no data-->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="visibleColumns?.length">
          No data matching the filter.
        </td>
      </tr>
    </table>
  </div>
</div>
