import { ChangeDetectorRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReweighHeaderErrorDetailsDialogComponent } from '@dialogs/reweigh-header-details-dialog/reweigh-header-error-details-dialog.component';
import { RwExceptionCdDialogComponent } from '@dialogs/rw-exception-cd-dialog/rw-exception-cd-dialog.component';
import { DmsDocument } from '@shared/classes/dms-document/dms-document';
import { ExtendedLiftScaleCalibrationHeader } from '@shared/classes/entities/extended-lift-scale-calibration-header';
import { ExtendedLiftScaleCalibrationHeaderUniq } from '@shared/classes/entities/extended-lift-scale-calibration-header-uniq';
import { ExtendedLogHeader } from '@shared/classes/entities/extended-log-header';
import { OperatorLogDetail } from '@shared/classes/entities/operator-log-detail';
import { LogHeaderSummaryGroups } from '@shared/classes/log-header-summary-group/log-header-summary-group';
import { Reweigh } from '@shared/classes/reweigh';
import { ReweighConstants } from '@shared/classes/reweigh-constants';
import { ReweighLogHeaderUtil } from '@shared/classes/utils/reweigh-log-header-util';
import { AppRoutes, ReweighLogHeaderFilterTypes, ReweighLogHeaderPathParams } from '@shared/enums';
import { CollapsableExpandableComponent } from '@shared/enums/collapsable-expandable-component';
import { ReweighLogDetailsViewType } from '@shared/enums/reweigh-log-detail-view-type';
import { LogHeaderSummaryTitles } from '@shared/enums/reweigh-log/reweigh-log-header-titles';
import { LogHeaderField } from '@shared/interfaces';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import { ReweighService } from '@shared/services/reweigh-service/reweigh.service';
import { ShipmentDetailsService } from '@shared/services/shipment-details/shipment-details.service';
import { UserRoleService } from '@shared/services/user-role';
import { LogHeader, LogHeaderShipment } from '@xpo-ltl/sdk-reweigh';
import { List } from 'immutable';
import { isEmpty as _isEmpty } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DmsApiWrapper } from '../../reweigh-log-summary/services/dms-api-wrapper.service';

@Injectable()
export abstract class ReweighLogSummaryBase implements OnInit, OnDestroy {
  static readonly COLLAPSE_EXPAND_LABEL_CONTENT: string = 'Collapse/Expand Columns';

  readonly ReweighLogDetailsViewType = ReweighLogDetailsViewType;
  readonly LogHeaderSummaryTitles = LogHeaderSummaryTitles;
  readonly LogHeaderSummaryGroups = LogHeaderSummaryGroups;
  readonly ExpandableCollapsableComponent = CollapsableExpandableComponent;
  readonly ReweighLogSummaryBase = ReweighLogSummaryBase;

  protected reweighServiceSubscription: Subscription;

  extendedLogHeader: ExtendedLogHeader;
  logDetails$: BehaviorSubject<List<OperatorLogDetail>> = new BehaviorSubject<List<OperatorLogDetail>>(List());
  logHeaderSummaryGroups: LogHeaderSummaryGroups;
  // todo pg: should probably use behaviorSubjects here for other stuff
  logHeaderId: number = undefined;
  imageCreatedInd: boolean = false;
  hasShipmentDimensions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasCurrentUserWriteAccess: boolean = false;
  logHeaderShipment: LogHeaderShipment;
  liftScaleCalibrationHeaders$: BehaviorSubject<List<ExtendedLiftScaleCalibrationHeader>> = new BehaviorSubject<
    List<ExtendedLiftScaleCalibrationHeader>
  >(List());
  liftScaleCalibrationHeadersUniq$: BehaviorSubject<List<ExtendedLiftScaleCalibrationHeaderUniq>> = new BehaviorSubject<
    List<ExtendedLiftScaleCalibrationHeaderUniq>
  >(List());
  shipmentDimensionsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    protected dmsApiWrapper: DmsApiWrapper,
    protected shipmentDetailsService: ShipmentDetailsService,
    protected router: Router,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected dialog: MatDialog,
    protected reweighService: ReweighService,
    protected userRoleService: UserRoleService,
    protected reweighLogSummaryService: ReweighLogSummaryService,
    protected titleService: Title,
    protected changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.hasCurrentUserWriteAccess = this.userRoleService.currentUserHasWriteAccess();
    this.reweighServiceSubscription = this.reweighService.subject.subscribe((aReweigh: Reweigh) => {
      this.setFromReweigh(aReweigh);
      this.changeDetection.markForCheck();
    });

    // otherwise not loaded when we come back to this view
    const lReweigh: Reweigh = this.reweighService.reweigh;
    if (this.extendedLogHeader?.logHeaderId !== lReweigh?.getLogHeaderId()) {
      this.titleService.setTitle(
        ReweighConstants.DEFAULT_PAGE_TITLE_PREFIX + ': Details for pro ' + this.extendedLogHeader?.proNbr
      );
      this.setFromReweigh(lReweigh);
      this.changeDetection.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.reweighServiceSubscription?.unsubscribe();
  }

  protected setFromReweigh(aReweigh: Reweigh) {
    this.extendedLogHeader = aReweigh.getLogHeader();
    this.logHeaderShipment = aReweigh.logHeaderShipment;
    this.liftScaleCalibrationHeaders$.next(aReweigh.liftScaleCalibrationHeaders);
    this.liftScaleCalibrationHeadersUniq$.next(aReweigh.liftScaleCalibrationHeadersUniq);
    // to be executed after component loading
    this.hasShipmentDimensions$.next(aReweigh.hasInspectionDimensions());
    this.shipmentDimensionsCount$.next(aReweigh.inspectionDimensions?.size || 0);
    if (this.extendedLogHeader) {
      this.logHeaderId = this.extendedLogHeader.logHeaderId;
      this.imageCreatedInd = this.extendedLogHeader.imageCreatedInd;
      this.logDetails$.next(List(this.extendedLogHeader.logDetail));
    }
    this.logHeaderSummaryGroups = new LogHeaderSummaryGroups(
      this.userRoleService,
      this.extendedLogHeader,
      this.logHeaderShipment
    );
  }

  onSummaryGroupItemLinkClicked(logHeaderField: LogHeaderField): void {
    if (logHeaderField.title === LogHeaderSummaryTitles.PRO_NBR) {
      this.navigateToProHistory();
    } else if (logHeaderField.title === LogHeaderSummaryTitles.ERROR_DETAILS) {
      this.onErrorDetailsCurrentLogHeaderClicked();
    } else if (logHeaderField.title === LogHeaderSummaryTitles.EXEPTION_CODE) {
      this.onExceptionCdClicked(this.reweighService.getLogHeader());
    }
  }

  navigateToShipmentDetails(event: Event) {
    event.stopPropagation();
    this.shipmentDetailsService.openShipmentDetails(
      this.reweighService.getShipmentInstanceId(),
      this.reweighService.getProNumber()
    );
  }

  navigateToProHistory(): void {
    const queryParams = {
      [ReweighLogHeaderPathParams.LOG_HEADER_FILTER_TYPE]: ReweighLogHeaderFilterTypes.PRO_SEARCH,
      [ReweighLogHeaderPathParams.PRO_NBR]: this.reweighService.getProNumber(),
    };
    this.router.navigate([AppRoutes.REWEIGH_LOG_HEADERS], { queryParams: queryParams });
  }

  navigateBack(): void {
    this.router.navigate([AppRoutes.REWEIGH_LOG_HEADERS]);
  }

  onViewCertificateClicked(): void {
    this.dmsApiWrapper
      .getDmsDocument$(this.reweighService.getProNumber(), this.reweighService.getLogHeaderCreateTimestamp())
      .subscribe((dmsDocument: DmsDocument) => {
        if (!_isEmpty(dmsDocument)) {
          const file: Blob = new Blob([dmsDocument.getDecodedData()], { type: dmsDocument.getMimeType() + ';base64' });
          const url: string = window.URL.createObjectURL(file);
          window.open(url);
        } else {
          this.reweighAppNotificationService.error('No document info available');
        }
      });
  }

  onDownloadCertificateClicked(): void {
    this.dmsApiWrapper
      .getDmsDocument$(this.reweighService.getProNumber(), this.reweighService.getLogHeaderCreateTimestamp())
      .subscribe((dmsDocument: DmsDocument) => {
        if (!_isEmpty(dmsDocument)) {
          const file: Blob = new Blob([dmsDocument.getDecodedData()], { type: dmsDocument.getMimeType() + ';base64' });
          const link: HTMLAnchorElement = document.createElement('a');
          if (link != null) {
            link.href = window.URL.createObjectURL(file);
            link.download = dmsDocument.getFileName();
            link.click();
          }
        } else {
          this.reweighAppNotificationService.error('No document info available');
        }
      });
  }

  onErrorDetailsCurrentLogHeaderClicked() {
    const dialogRef = this.dialog.open(
      ReweighHeaderErrorDetailsDialogComponent,
      ReweighLogHeaderUtil.getDialogConfig(this.reweighService.getLogHeader())
    );
  }

  onExceptionCdClicked(logHeader: LogHeader) {
    this.dialog.open(RwExceptionCdDialogComponent, RwExceptionCdDialogComponent.getDialogConfig(logHeader, null));
  }

  isFieldVisible(secondGroup: LogHeaderField): boolean {
    let result: boolean = true;
    if (!this.userRoleService.currentUserHasWriteAccess()) {
      result = !secondGroup.isNotVisibleForReadonlyView;
    }
    return result;
  }

  onCollapseExpandColumnsClicked($event: MouseEvent, aComponent: CollapsableExpandableComponent) {
    $event.stopImmediatePropagation();
    this.reweighLogSummaryService.onCollapseExpandClicked(aComponent);
    this.changeDetection.markForCheck();
  }
}
