<div class="overall">
  <div class="close">
    <b>PRO: {{ proNbrTxt }}</b>
    <button
      mat-icon-button
      *ngIf="isDialog"
      (click)="close()"
      class="close"
      matTooltip="Click to close or Escape or Click outside the dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content">
    <mat-tab-group
      data-test="tab-group"
      [selectedIndex]="activeTabIndex"
      (selectedIndexChange)="onMatTabChanged($event)"
    >
      <mat-tab label="Full Summary">
        <app-reweigh-log-summary-page></app-reweigh-log-summary-page>
      </mat-tab>
      <mat-tab label="Dispute Summary" *ngIf="hasCurrentUserWriteAccess">
        <!--        lazy loaded workaround for shipment dims not refreshed well -->
        <ng-template matTabContent>
          <app-reweigh-log-summary2></app-reweigh-log-summary2>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
