import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OperatorLogDetail } from '@shared/classes/entities/operator-log-detail';
import { ScaleTestUtil } from '@shared/classes/utils/scale-test-util';
import { ScaleTestRouting } from '@shared/interfaces/scale-test-routing';
import { ReweighLogHeaderViewsService } from '@shared/services/reweigh-log-header-views.service';
import { UserRoleService } from '@shared/services/user-role';
import { UiEntityTableCellComponentBase } from '../../../../directives/ui-entity-table-cell-component-base';

@Component({
  selector: 'app-reweigh-log-detail-table-custom',
  templateUrl: './reweigh-log-detail-table-custom.component.html',
  styleUrls: ['./reweigh-log-detail-table-custom.component.scss'],
})
export class ReweighLogDetailTableCustomComponent extends UiEntityTableCellComponentBase<OperatorLogDetail>
  implements OnInit {
  hasCurrentUserWriteAccess: boolean = false;

  constructor(
    protected router: Router,
    protected reweighLogHeaderViewsService: ReweighLogHeaderViewsService,
    protected userRoleService: UserRoleService
  ) {
    super();
    this.hasCurrentUserWriteAccess = this.userRoleService.currentUserHasWriteAccess();
  }

  ngOnInit(): void {}

  onScaleTestSearchClicked(aUiEntityData: OperatorLogDetail) {
    ScaleTestUtil.navigateToScaleTestsFor(ScaleTestRouting.buildFromOperatorLogDetail(aUiEntityData), this.router);
    // might be not a dialog in case top component will not be set
    if (this.reweighLogHeaderViewsService.reweighLogHeaderViewsComponent) {
      this.reweighLogHeaderViewsService.reweighLogHeaderViewsComponent.close();
    }
  }
}
