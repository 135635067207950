import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { UiEntity } from '@shared/classes/entities/ui-entity';
import { UiEntityExpandableTableEntity } from '@shared/classes/entities/ui-entity-expandable-table-entity';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { UiEntityTableEntity } from '@shared/classes/entities/ui-entity-table-entity';
import { UiEntityUtil } from '@shared/classes/utils/ui-entity-util';
import { UiEntityMatTableComponent } from '@shared/components/ui-entity-mat-table/ui-entity-mat-table-component';
import { List } from 'immutable';
import { UiEntityTableCellComponentBase } from '../../../directives/ui-entity-table-cell-component-base';

/**
 * datasource is found into entity which implements
 *
 *
 */
@Injectable()
export abstract class UiEntityExpandableMatTableComponent<
  TUiEntityExpandedTableEntity extends UiEntityTableEntity & UiEntityExpandableTableEntity<UiEntityTableEntity>,
  TtableCustomComponent extends UiEntityTableCellComponentBase<TUiEntityExpandedTableEntity>
> extends UiEntityMatTableComponent<TUiEntityExpandedTableEntity, TtableCustomComponent> {
  readonly EXPAND_COLLAPSE_COL_DEF_TITLE: string = 'expandCollapse';

  expandedUiEntityProperties: UiEntityProperty[];
  expandedVisibleColumns: string[];
  isTableColumnsExpandedForExpandedRow: boolean = false;
  isExpandedRow: boolean = true;
  // is expandable functionality enabled
  isExpandableRowsEnabled: boolean = true;

  /**
   * Override
   */
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.expandedUiEntityProperties = this.getExpandedUiEntity().properties;
    this.expandedVisibleColumns = this.getExpandedVisibleColumns().toArray();
    this.changeDetectorRefs.markForCheck();
  }

  getExpandedVisibleColumns(): List<string> {
    const result: List<string> = UiEntityUtil.toVisibleColumnNames(
      this.getExpandedUiEntity(),
      null,
      !this.isTableColumnsExpandedForExpandedRow,
      this.isTableColumnsExpandedForExpandedRow
    );
    // console.debug('getVisibleColumns for ' + this.getUiEntity().entityTypeName, result);
    return result;
  }

  abstract getExpandedUiEntity(): UiEntity;

  getExpandedElementTitle(aUiEntityProperty: UiEntityProperty): string {
    return UiEntityUtil.getHeaderName(aUiEntityProperty.propertyName, this.getExpandedUiEntity().properties);
  }

  getExpandedElementTableHeaderTooltip(aUiEntityProperty: UiEntityProperty): string {
    const result: string = this.getExpandedElementTableHeaderTitle(aUiEntityProperty);
    return result;
  }

  getExpandedElementTableHeaderTitle(aUiEntityProperty: UiEntityProperty): string {
    return UiEntityUtil.getHeaderName(aUiEntityProperty.propertyName, this.getExpandedUiEntity().properties);
  }

  /**
   * Show/hide expanded row
   * @param $event
   */
  toggleShowExpandedRow($event: MouseEvent) {
    this.isExpandedRow = !this.isExpandedRow;
  }

  /**
   * Show more/less columns for expanded row table
   *
   * @param $event
   */
  toggleTableColumnsExpandedForExpandedRow($event: MouseEvent) {
    this.isTableColumnsExpandedForExpandedRow = !this.isTableColumnsExpandedForExpandedRow;
    this.expandedVisibleColumns = this.getExpandedVisibleColumns().toArray();
    // console.debug('toggle expand/collapse, columns:', this.expandedVisibleColumns);
  }

  /**
   * override
   */
  getVisibleColumns(): List<string> {
    const tmp: string[] = super.getVisibleColumns().toArray();
    if (this.isExpandableRowsEnabled) {
      tmp.unshift(this.EXPAND_COLLAPSE_COL_DEF_TITLE);
    }
    return List(tmp);
  }

  abstract isExpandDetailRowsButtonVisible(): boolean;

  resetTableColumns() {
    this.expandedVisibleColumns = this.getExpandedVisibleColumns().toArray();
    this.visibleColumns = this.getVisibleColumns().toArray();
  }

  getNgClassForExpandedRow(expandedColIndex: number): string {
    let result: string;

    if (expandedColIndex % 2 !== 0) {
      result = 'expandedRowEven';
    }
    result = 'expandedRowEven';
    return result;
  }

  getExpandedRowClass(dataIndex: number, renderIndex: number, row: any, selection: SelectionModel<any>): any {
    const lNotExpandedRowEven: boolean = renderIndex % 4 === 2;
    const result: any = {
      hovered: row.hovered,
      highlightedTableRow: selection.isSelected(row),
      notExpandedRowEven: lNotExpandedRowEven,
    };
    // console.debug('getExpandedRowClass, dataIndex:' + dataIndex + ' renderIndex:' + renderIndex);
    return result;
  }
}
