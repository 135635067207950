import { Injectable } from '@angular/core';
import { ReweighError } from '@shared/classes/error/reweigh-error';
import { isEmpty as _isEmpty } from 'lodash';

@Injectable()
export class RequestValidator {
  constructor() {}

  static validateRequestStringIsNotNullOrBlank(requestString: string, fieldName: string): void {
    if (!requestString || requestString.trim().length === 0) {
      throw new ReweighError(`${fieldName} cannot be empty.`);
    }
  }
}
